import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelSelect as _vModelSelect, vModelCheckbox as _vModelCheckbox, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full mb-5"
}
const _hoisted_2 = {
  key: 1,
  class: "w-full mb-5"
}
const _hoisted_3 = {
  key: 2,
  class: "w-full mb-5"
}
const _hoisted_4 = {
  key: 3,
  class: "w-full mb-5"
}
const _hoisted_5 = {
  key: 4,
  class: "w-full mb-5"
}
const _hoisted_6 = {
  key: 5,
  class: "w-full mb-5"
}
const _hoisted_7 = { className: "flex items-center justify-between" }
const _hoisted_8 = {
  key: 6,
  class: "w-full mb-5"
}
const _hoisted_9 = { className: "flex items-center justify-between" }
const _hoisted_10 = {
  key: 7,
  class: "w-full mb-5"
}
const _hoisted_11 = {
  for: "credit-amount",
  class: "block text-sm font-medium text-gray-700"
}
const _hoisted_12 = {
  for: "credit-amount",
  class: "block text-sm font-medium text-gray-700"
}
const _hoisted_13 = {
  key: 8,
  class: "w-full mb-5"
}
const _hoisted_14 = { class: "flex" }

import { ref, onMounted, watch } from 'vue'
import Admin from '@/composables/admin/index'
import { resetErrors, handleApiError } from '@/utils/common'


export default /*@__PURE__*/_defineComponent({
  __name: 'SaveAgent',
  props: ['agent', 'action'],
  emits: ['success', 'error'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit
const { updateAgent } = Admin()
const isLoading = ref<boolean>(false)
const form = ref({
   loyaltyThreshold: 0,
   status: 0,
   credit: {
      permission: 0,
      amount: 0,
      used: 0,
      days: 0,
      reset: '',
      resetCreditUsed: '',
      daysLeft: 0
   },
   commission: {
      agent: 0,
      admin: 0
   }
})
const errors = ref({
   loyaltyThreshold: false
})

watch(() => form.value.credit.permission, (newValue : any, oldValue: any) => {
   if (newValue === '1') {
      
   } else if (newValue === '0') {
      
   }
})

onMounted(() => {
   mounted() 
})

const mounted = async () => {

   if (props.agent) {
      form.value = {
         loyaltyThreshold: props.agent.loyalty.threshold,
         status: props.agent.status,
         credit: {...props.agent.credit, reset: '', resetCreditUsed: ''},
         commission: {
            agent: props.agent.commission,
            admin: props.agent.admin_commission,
         },
      }
   }
}

const validateForm = () : boolean => {

   let response = true
   
   if (props.action == 'loyalty' && form.value.loyaltyThreshold == 0) {
      errors.value.loyaltyThreshold = true
      response = false
   }

   return response 
}

const handleSubmit = async () => {

   resetErrors(errors.value)
   const isValid = validateForm()

   if (isValid) {
      
      isLoading.value = true
      const response = await updateAgent(props.agent.id, form.value, props.action)
      isLoading.value = false
      if (response.status == 200) {
         emit('success')
      } else {
         const error = handleApiError(response)
         emit('error', error)
      }
   }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (__props.action == 'loyalty')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("label", {
            for: "threshold",
            class: _normalizeClass([!errors.value.loyaltyThreshold ? 'text-gray-700' : 'text-theme-error', "block text-sm font-medium"])
          }, "Loyalty Threshold", 2),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.loyaltyThreshold) = $event)),
            type: "text",
            class: _normalizeClass([!errors.value.loyaltyThreshold ? 'border-gray-300 text-gray-500' : 'border-theme-error text-theme-error', "mt-1 outline-none border rounded-sm h-10 w-full block pl-2 text-sm"]),
            id: "threshold"
          }, null, 2), [
            [_vModelText, form.value.loyaltyThreshold]
          ])
        ]))
      : _createCommentVNode("", true),
    (__props.action == 'commission')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[9] || (_cache[9] = _createElementVNode("label", {
            for: "admin-commission",
            class: "block text-sm font-medium text-gray-700"
          }, "Agent Commission", -1)),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.commission.admin) = $event)),
            type: "text",
            id: "admin-commission",
            class: "mt-1 border-gray-300 text-gray-500 outline-none border rounded-sm h-10 w-full block pl-2 text-sm"
          }, null, 512), [
            [_vModelText, form.value.commission.admin]
          ])
        ]))
      : _createCommentVNode("", true),
    (__props.action == 'credit')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _cache[11] || (_cache[11] = _createElementVNode("label", {
            for: "status",
            class: "block text-sm font-medium text-gray-700"
          }, "Has Credit Permission", -1)),
          _withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.value.credit.permission) = $event)),
            class: "block mt-1 ring-1 ring-gray-300 rounded-sm bg-white w-full rounded-md border-0 py-1.5 text-gray-900 outline-none h-10 sm:max-w-xs sm:text-sm sm:leading-6"
          }, _cache[10] || (_cache[10] = [
            _createElementVNode("option", { value: "1" }, "Yes", -1),
            _createElementVNode("option", { value: "0" }, "No", -1)
          ]), 512), [
            [_vModelSelect, form.value.credit.permission]
          ])
        ]))
      : _createCommentVNode("", true),
    (__props.action == 'credit' && form.value.credit.permission == 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _cache[12] || (_cache[12] = _createElementVNode("label", {
            for: "credit-amount",
            class: "block text-sm font-medium text-gray-700"
          }, "Total Credit Amount", -1)),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.value.credit.amount) = $event)),
            type: "text",
            id: "credit-amount",
            class: "mt-1 border-gray-300 text-gray-500 outline-none border rounded-sm h-10 w-full block pl-2 text-sm"
          }, null, 512), [
            [_vModelText, form.value.credit.amount]
          ])
        ]))
      : _createCommentVNode("", true),
    (__props.action == 'credit' && form.value.credit.permission == 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _cache[13] || (_cache[13] = _createElementVNode("label", {
            for: "credit-days",
            class: "block text-sm font-medium text-gray-700"
          }, "Total No of days", -1)),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((form.value.credit.days) = $event)),
            type: "number",
            id: "credit-days",
            class: "mt-1 border-gray-300 text-gray-500 outline-none border rounded-sm h-10 w-full block pl-2 text-sm"
          }, null, 512), [
            [_vModelText, form.value.credit.days]
          ])
        ]))
      : _createCommentVNode("", true),
    (__props.action == 'credit' && form.value.credit.permission == 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _cache[14] || (_cache[14] = _createElementVNode("label", {
              for: "credit-reset",
              class: "block text-sm font-medium text-gray-700"
            }, "Reset Days Consumed", -1)),
            _withDirectives(_createElementVNode("input", {
              id: "credit-reset",
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((form.value.credit.reset) = $event)),
              value: "yes",
              type: "checkbox",
              class: "h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            }, null, 512), [
              [_vModelCheckbox, form.value.credit.reset]
            ])
          ]),
          _cache[15] || (_cache[15] = _createElementVNode("p", { class: "text-gray-500 text-xs" }, "It will reset the number of days consumed by agent", -1))
        ]))
      : _createCommentVNode("", true),
    (__props.action == 'credit' && form.value.credit.permission == 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _cache[16] || (_cache[16] = _createElementVNode("label", {
              for: "credit-used-reset",
              class: "block text-sm font-medium text-gray-700"
            }, "Reset Credit Used", -1)),
            _withDirectives(_createElementVNode("input", {
              id: "credit-used-reset",
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((form.value.credit.resetCreditUsed) = $event)),
              value: "yes",
              type: "checkbox",
              class: "h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            }, null, 512), [
              [_vModelCheckbox, form.value.credit.resetCreditUsed]
            ])
          ]),
          _cache[17] || (_cache[17] = _createElementVNode("p", { class: "text-gray-500 text-xs" }, "It will reset the credit consumed by agent to 0", -1))
        ]))
      : _createCommentVNode("", true),
    (__props.action == 'credit' && form.value.credit.permission == 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("label", _hoisted_11, "Total Credit Left : " + _toDisplayString(form.value.credit.amount - form.value.credit.used), 1),
          _createElementVNode("label", _hoisted_12, "Total Days Left : " + _toDisplayString(form.value.credit.daysLeft), 1)
        ]))
      : _createCommentVNode("", true),
    (__props.action == 'status')
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _cache[19] || (_cache[19] = _createElementVNode("label", {
            for: "status",
            class: "block text-sm font-medium text-gray-700"
          }, "Agent Status", -1)),
          _withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((form.value.status) = $event)),
            class: "block mt-1 ring-1 ring-gray-300 rounded-sm bg-white w-full rounded-md border-0 py-1.5 text-gray-900 outline-none h-10 sm:max-w-xs sm:text-sm sm:leading-6"
          }, _cache[18] || (_cache[18] = [
            _createElementVNode("option", { value: "1" }, "Active", -1),
            _createElementVNode("option", { value: "0" }, "Inactive", -1)
          ]), 512), [
            [_vModelSelect, form.value.status]
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("button", {
        onClick: _cache[8] || (_cache[8] = ($event: any) => (handleSubmit())),
        type: "button",
        class: "ml-auto mt-20 bg-indigo-600 py-2 px-10 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-indigo-700 outline-none"
      }, " Save ")
    ])
  ], 64))
}
}

})