import { getAdminToken, getUserToken, getBackendBaseUrl } from "@/utils/common"
import { Locality } from '@/types/index'
import axios from "axios"

const baseUrl : string = getBackendBaseUrl()

const Admin = () => {

   const getHeaders = () => { 
      return {
         'Authorization': `Bearer ${getAdminToken()}`,
         'Content-Type': 'application/json',
      }
   }

   const allCars = async () : Promise<any> => {
      try {
         const response = await axios.get(baseUrl + 'api/admin/cars/list', {params: {}, headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }
   
   const allPages = async () : Promise<any> => {
      try {
         const response = await axios.get(baseUrl + 'api/admin/pages/list', {params: {}, headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }
   
   const allAgents = async () : Promise<any> => {
      try {
         const response = await axios.get(baseUrl + 'api/admin/agents/list', {params: {}, headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const updateAgent = async (id: number, data: any, action: any) : Promise<any> => {

      let dataa

      if (action == 'loyalty') {
         dataa = {loyaltyThreshold: data.loyaltyThreshold}
      } else if (action == 'commission') {
         dataa = {commission: data.commission}
      } else if (action == 'status') {
         dataa = {status: data.status}
      } else if (action == 'credit') {
         dataa = {credit: data.credit}
      }

      try {
         const response = await axios.patch(baseUrl + 'api/admin/agents/update/' + id, dataa, {headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const agentSubAgents = async (agentId: number) : Promise<any> => {
      try {
         const response = await axios.get(`${baseUrl}api/admin/agents/${agentId}/sub-agents`, {params: {}, headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }
   
   const agentPerformance = async (scope: any) : Promise<any> => {

      try {
         const response = await axios.get(`${baseUrl}api/admin/reports/agent-perfomance/summary/${scope.id}` + '', {params: {}, headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }
   
   const agentBookingsResport = async (agentId : number, scope: any) : Promise<any> => {

      try {
         const response = await axios.get(`${baseUrl}api/admin/reports/agent-perfomance/bookings/${agentId}/${scope.id}` + '', {params: {}, headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }
   
   const salesReport = async (scope: any) : Promise<any> => {

      try {
         const response = await axios.get(`${baseUrl}api/admin/reports/sales-report/${scope.id}`, {params: {}, headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const createPage = async (data: any) : Promise<any> => {
      try {
         const response = await axios.post(baseUrl + 'api/admin/pages/create', {title: data.name, banner_heading: data.banner.heading, media_type: data.banner.media.type, media_source: data.banner.media.data, banner_sub_heading: data.banner.subHeading, banner_info_text: data.banner.text, banner_button_text: data.banner.button.title, banner_button_url: data.banner.button.url, section_title: data.section.title, section_info_text: data.section.text, show_form: data.showSupplierForm}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }
   
   const updatePage = async (id: number, data: any) : Promise<any> => {
      try {
         const response = await axios.put(baseUrl + 'api/admin/pages/update/' + id, {title: data.name, banner_heading: data.banner.heading, media_type: data.banner.media.type, media_source: data.banner.media.data, banner_sub_heading: data.banner.subHeading, banner_info_text: data.banner.text, banner_button_text: data.banner.button.title, banner_button_url: data.banner.button.url, section_title: data.section.title, section_info_text: data.section.text, show_form: data.showSupplierForm}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const getPageDetails = async (slug: string) : Promise<any> => {
      try {
         const response = await axios.get(baseUrl + 'api/pages/read/' + slug, {params: {}, headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const allBookings = async () : Promise<any> => {
      try {
         const response = await axios.get(baseUrl + 'api/admin/bookings/list', {params: {}, headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const allCarTypes = async () : Promise<any> => {
      try {
         const response = await axios.get(baseUrl + 'api/admin/car-types/list', {params: {}, headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const allSuppliers = async () : Promise<any> => {
      try {
         const response = await axios.get(baseUrl + 'api/admin/suppliers/list', {params: {}, headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const createCar = async (data: any) : Promise<any> => {
      try {
         const response = await axios.post(baseUrl + 'api/admin/cars/create', {name: data.name, photo: data.photo, type: data.type, suppliers: data.suppliers, displacement: data.displacement, transmission: data.transmission, meet_greet: data.meet_greet}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }
   
   const updateCar = async (id: number, data: any) : Promise<any> => {
      try {
         const response = await axios.put(`${baseUrl}api/admin/cars/update/${id}`, {name: data.name, photo: data.photo, type: data.type, suppliers: data.suppliers, displacement: data.displacement, transmission: data.transmission, meet_greet: data.meet_greet}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const removePage = async (id: number) : Promise<any> => {
      try {
         const response = await axios.delete(`${baseUrl}api/admin/pages/${id}/delete`, { headers: getHeaders(), data: { id: id } })
         return response
      } catch (err : any) {
         return err
      }
   }

   const allLocations = async (type: string) : Promise<any> => {
      try {
         const response = await axios.get(baseUrl + `api/admin/locations/${type}/list`, {params: {}, headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const carLocations = async (id: number, type: string) : Promise<any> => {
      try {
         const response = await axios.get(baseUrl + `api/admin/cars/${id}/locations/${type}`, {params: {}, headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const createCarChaufferLocation = async (id: number, location: Locality, rate: number) : Promise<any> => {
      try {
         const response = await axios.put(baseUrl + `api/admin/cars/${id}/locations/chauffer`, {locality: location, rate: rate}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const createCarTransferLocation = async (id: number, fromLocation: Locality, toLocation: Locality, rate: number) : Promise<any> => {
      try {
         const response = await axios.put(baseUrl + `api/admin/cars/${id}/locations/transfer`, {from_locality: fromLocation, to_locality: toLocation, rate: rate}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const removeCar = async (id: number) : Promise<any> => {
      try {
         const response = await axios.delete(`${baseUrl}api/admin/cars/${id}/delete`, { headers: getHeaders(), data: { id: id } })
         return response
      } catch (err : any) {
         return err
      }
   }

   const removeCarLocation = async (id: number, type: string) : Promise<any> => {
      try {
         const response = await axios.delete(`${baseUrl}api/admin/cars/locations/${type}/${id}/delete`, { headers: getHeaders(), data: { id: id } })
         return response
      } catch (err : any) {
         return err
      }
   }

   return {
      allCars,
      allCarTypes,
      allSuppliers,
      createCar,
      allLocations,
      carLocations,
      createCarChaufferLocation,
      createCarTransferLocation,
      allBookings,
      removeCar,
      removeCarLocation,
      updateCar,
      allPages,
      createPage,
      updatePage,
      removePage,
      getPageDetails,
      allAgents,
      updateAgent,
      agentPerformance,
      salesReport,
      agentBookingsResport,
      agentSubAgents
   }
}

export default Admin