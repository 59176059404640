import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, normalizeClass as _normalizeClass, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "px-4 sm:px-6 lg:px-8" }
const _hoisted_2 = { class: "sm:flex sm:items-center" }
const _hoisted_3 = { class: "sm:flex-auto" }
const _hoisted_4 = { class: "text-base font-semibold leading-6 text-gray-900" }
const _hoisted_5 = { class: "flex flex-wrap items-center justify-end gap-6 sm:flex-nowrap mb-6 my-12" }
const _hoisted_6 = { class: "order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:leading-7" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "mt-8 flow-root" }
const _hoisted_9 = { class: "-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" }
const _hoisted_10 = { class: "flex flex-col min-w-full py-2 align-middle sm:px-6 lg:px-8" }
const _hoisted_11 = { class: "overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg" }

import { computed, onMounted, ref, watch } from 'vue'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import BookingsTable from '@/components/account/BookingsTable.vue'
import Admin from '@/composables/admin'
import { isAdminLoggedIn, logout } from '@/utils/common'
import router from '@/router'


export default /*@__PURE__*/_defineComponent({
  __name: 'agent-bookings',
  props: ['agentId'],
  setup(__props) {

const { agentBookingsResport, agentSubAgents } = Admin()
const isLoading = ref<boolean>(true)
let authAdminUser = ref<any>(null)
const bookings = ref<any>(null)
const mySubAgents = ref([])
const agent = ref<any>(null)
const dateRangeConfig : any = {
   mode: 'range',
   altFormat: 'd-m-Y',
   altInput: true,
   dateFormat: 'd-m-Y'
}
const dates = ref<string | null>(null)
const scopes = ref<any>(
   [
      {id: 'today', name: 'Today', isCurrent: false},
      {id: 'yesterday', name: 'Yesterday', isCurrent: false},
      {id: 'last_7_days', name: 'Last 7 Days', isCurrent: false},
      {id: 'this_month', name: 'This Month', isCurrent: false},
      {id: 'last_month', name: 'Last Month', isCurrent: false},
      {id: 'all_time', name: 'All Time', isCurrent: true},
      {id: 'custom', name: 'Custom Duration', isCurrent: false}
   ]
)
const currentScope = computed(() => scopes.value.find((scope: any) => scope.isCurrent))

const props = __props

onMounted(async () => {

   authAdminUser = isAdminLoggedIn()
   await fetchBookings()
   await fetchSubAgents()
})

watch(dates, (newValue, oldValue) => {

   if (newValue?.includes('to')) {
      
      scopes.value.forEach((scope: any) => {
         if (!scope.id.includes('custom')) {
            scope.isCurrent = false
         } else {
            scope.isCurrent = true
            const dateRange = `${newValue.split(' to ')[0]}_${newValue.split(' to ')[1]}`
            scope.id = `custom_${dateRange}`
         }
      })

      fetchBookings()
   }
})

const fetchBookings = async () => {

   isLoading.value = true
   let response : any

   response = await agentBookingsResport(props.agentId, currentScope.value)
   isLoading.value = false

   if (response.status == 200) {
      
      bookings.value = response.data
   } else if (response.response && response.response.status == 401) {
      logout(router)
   }
}

const fetchSubAgents = async () => {

   isLoading.value = true
   let response : any

   response = await agentSubAgents(props.agentId)
   isLoading.value = false

   if (response.status == 200) {
      
      agent.value = response.data.agent
      mySubAgents.value = response.data.subAgents
      console.log(agent.value)
   } else if (response.response && response.response.status == 401) {
      logout(router)
   }
}

const handleScopeUpdate = async (item: any) => {

   scopes.value.forEach((scope: any) => {
      scope.isCurrent = false
   })

   scopes.value.find((scope: any) => scope.id === item.id).isCurrent = true
   dates.value = ''

   await fetchBookings()
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(agent.value ? agent.value.name : ''), 1),
        _cache[1] || (_cache[1] = _createElementVNode("p", { class: "mt-2 text-sm text-gray-700" }, "View bookings of an agency", -1))
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scopes.value, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.id,
            class: _normalizeClass([item.isCurrent ? 'text-theme-active' : 'text-gray-700', "cursor-pointer"])
          }, [
            (!item.id.includes('custom'))
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  onClick: ($event: any) => (handleScopeUpdate(item))
                }, _toDisplayString(item.name), 9, _hoisted_7))
              : (_openBlock(), _createBlock(_unref(flatPickr), {
                  key: 1,
                  modelValue: dates.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dates).value = $event)),
                  config: dateRangeConfig,
                  class: _normalizeClass([item.isCurrent ? 'text-theme-gray-5' : 'text-theme-active', "border px-3 outline-none"]),
                  placeholder: "Custom date range"
                }, null, 8, ["modelValue", "class"]))
          ], 2))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(BookingsTable, {
              subAgents: mySubAgents.value,
              bookings: bookings.value,
              isLoading: isLoading.value
            }, null, 8, ["subAgents", "bookings", "isLoading"])
          ])
        ])
      ])
    ])
  ]))
}
}

})