import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-12 md:gap-10" }
const _hoisted_2 = { class: "col-span-12 md:col-span-3 flex flex-col h-fit order-2 md:order-1 mt-12 md:mt-0" }
const _hoisted_3 = { class: "text-theme-gray-2" }
const _hoisted_4 = { class: "col-span-12 md:col-span-9 flex flex-col order-1 md:order-2" }
const _hoisted_5 = { class: "flex flex-col" }
const _hoisted_6 = { class: "grid grid-cols-2 border-b border-r border-l border-theme-gray-7 md:divide-x divide-y divide-theme-gray-7 shadow-custom" }
const _hoisted_7 = { class: "col-span-6 md:col-span-1 px-5 py-4 flex flex-col" }
const _hoisted_8 = { class: "py-2" }
const _hoisted_9 = { class: "col-span-6 md:col-span-1 px-5 py-4 flex flex-col" }
const _hoisted_10 = { class: "py-2" }
const _hoisted_11 = {
  key: 0,
  class: "rounded-md bg-blue-50 p-4 mt-4",
  style: {"display":"none"}
}
const _hoisted_12 = { key: 1 }
const _hoisted_13 = {
  key: 2,
  class: "flex flex-col mt-10 divide-y divide-theme-gray-7"
}
const _hoisted_14 = {
  key: 3,
  class: "my-12 text-center"
}
const _hoisted_15 = { class: "hidden flex flex-col" }
const _hoisted_16 = { class: "flex flex-row items-center gap-5" }
const _hoisted_17 = { class: "rounded-full border-2 border-theme-gray-7 flex flex-row items-center justify-center w-[56px] h-[56px]" }
const _hoisted_18 = { class: "p-2 flex items-center justify-center rounded-full border border-theme-gray-7 bg-theme-gray-7 group-hover:bg-theme-gray-7" }
const _hoisted_19 = { class: "extra-options flex flex-col divide-y divide-theme-gray-7 border-t border-b border-theme-gray-7 mt-12" }
const _hoisted_20 = { class: "grid grid-cols-6 md:divide-x divide-theme-gray-7" }
const _hoisted_21 = { class: "col-span-6 md:col-span-1 md:px-5 py-4 flex flex-col items-center justify-center" }
const _hoisted_22 = { class: "gap-2 uppercase rounded-full px-8 py-4 border-2 border-theme-gray-7 bg-theme-active text-white text-size-2 flex items-center justify-center cursor-pointer w-full md:w-auto md:ml-auto transition-all ease-in-out duration-500" }

import { computed, onMounted, ref, watch } from 'vue'
import Dropdown from '@/components/Dropdown.vue'
import { PASSENGERS, DOORS, SUITCASES, TRANSMISSION_TYPES } from '@/utils/constants'
// @ts-ignore
import $ from 'jquery'
import BookingSummary from '@/components/BookingSummary.vue'
import { LOADER_MINIMUM_TIME } from '@/utils/constants'
import CarResult from '@/components/CarResult.vue'
import { UserSearch, Car as CarData } from '@/types'
import { decodeFromQuery, retrieveUserSearch, saveUserTempSearch } from '@/services/search-service'
import CarService from '@/services/car-service'
import Deal from '@/composables/Deal'
import CarSkeleton from '@/skeletons/CarSkeleton.vue'
import BookingService from '@/services/booking-service'
import { handleApiError, getSessionPrefix, saveBookingNumber, disableScroll, enableScroll, isLoggedIn, saveDealId, getHoursPerDay } from '@/utils/common'
import { useRouter } from 'vue-router'
import Loader from '@/widgets/Loader.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ChooseVehicle',
  props: ['currentStep'],
  setup(__props) {

const props = __props
const authUser = isLoggedIn()
const router = useRouter()
const passengers = PASSENGERS
const doors = DOORS
const transmissionTypes = TRANSMISSION_TYPES
const suitcases = SUITCASES
const userSearch = ref<UserSearch | null>(null)
const { getCars, saveSelectedCar }  = CarService()
const { createDeal } = Deal()
const cars = ref<CarData[] | null>(null)
const isLoading = ref<boolean>(false)
const isBusy = ref<boolean>(false)
const selectedCar = ref<CarData | null>(null)
const { createBooking } = BookingService()
const timeElapsed = ref<number>(0)
const bookingProceed = ref()
const filters = ref<any>({
   passengers: null,
   doors: null,
   suitcases: null,
   transmission: null
})

const filteredCars = computed(() => {

   const days = (userSearch.value?.hours ?? 0) / getHoursPerDay()

   return cars.value?.filter((car: any) => {
      return (
         (!filters.value.passengers || car.type.passengers >= filters.value.passengers) &&
         (!filters.value.doors || car.type.doors === filters.value.doors) &&
         (!filters.value.suitcases || car.type.bags >= filters.value.suitcases) &&
         (!filters.value.transmission || car.transmission === filters.value.transmission)
      )
   }).map((car: any) => {
      
      let price = 0
      if (userSearch.value?.type === 'hourly' && car.chaufferLocation) {
        price = parseFloat(car.chaufferLocation.rate_per_hour) * (days * (car.supplier && car.supplier.hours ? car.supplier.hours : 0));
      } else if (userSearch.value?.type === 'distance' && car.transferLocation) {
        price = parseFloat(car.transferLocation.rate_per_km) * days * (car.transferLocation.rate || 0);
      }
      return { ...car, price }
   }).sort((a: any, b: any) => a.price - b.price)
})

const toggleExtraOptions = () => {
   $('.extra-options').slideToggle()
}

const mounted = async () => {
   userSearch.value = retrieveUserSearch()

   isLoading.value = true
   disableScroll()
   const startTime = performance.now()
   const response = await getCars(userSearch.value)
   timeElapsed.value = performance.now() - startTime
   if (timeElapsed.value < LOADER_MINIMUM_TIME) {

      const diff = LOADER_MINIMUM_TIME - timeElapsed.value
      setTimeout(() => {
         enableScroll()
         isLoading.value = false
      }, diff)
   } else {

      enableScroll()
      isLoading.value = false
   }

   if (response && response.success) {
      cars.value = response.data.cars

      if (cars.value?.length == 0) {
         router.push({name: 'BookingLead', params: {service: ''}})
      }
   } else {
      
   }
}

const handleCarSelected = (car: CarData) => {
   selectedCar.value = car
   bookingProceed.value.click()
}

const handleBookingProceed = async () => {

   if (selectedCar.value && !isBusy.value) {
      saveSelectedCar(selectedCar.value)

      isBusy.value = true
      const response = await createBooking(selectedCar.value, userSearch.value as UserSearch)
      isBusy.value = false
      
      if (response.success) {

         const prefix = getSessionPrefix()
         saveBookingNumber(response.data.data.number)

         if (authUser && (authUser.user.type == 'agent' || authUser.user.type == 'corporate')) {
            const dealResponse = await createDeal(response.data.data.number)
            if (dealResponse.status == 201) {
               saveDealId(dealResponse.data.id)
            }
         }

         router.push({name: 'BookingWizard', params: {step: 'contact-details'}})
      } else {
         const error = handleApiError(response)
         console.log(error)
      }
   }
}

const handleDropItemSelected = (item: any, name: string) => {

   if (name == 'passengers') {
      filters.value.passengers = item.value.value === '' ? null : item.value.value
   } else if (name == 'suitcases') {
      filters.value.suitcases = item.value.value === '' ? null : item.value.value
   } else if (name == 'doors') {
      filters.value.doors = item.value.value === '' ? null : item.value.value
   } else if (name == 'transmission') {
      filters.value.transmission = item.value.value === '' ? null : item.value.value
   } 
}

const handleEditSearch = () => {
   const search = retrieveUserSearch()
   saveUserTempSearch(search)
   router.push({name: 'Home'})
}

onMounted(() => {
   mounted()
})


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(BookingSummary, { currentStep: __props.currentStep }, null, 8, ["currentStep"]),
        _createElementVNode("div", {
          onClick: handleEditSearch,
          class: "gap-4 uppercase mx-auto mt-12 rounded-full w-full md:px-8 py-4 border-2 border-theme-gray-7 bg-theme-gray-7 hover:bg-white text-theme-gray-2 text-size-2 flex items-center justify-center cursor-pointer ml-auto transition-all ease-in-out duration-500"
        }, [
          _createElementVNode("span", _hoisted_3, [
            _createVNode(_component_font_awesome_icon, {
              size: "lg",
              icon: "fa-solid fa-chevron-left"
            })
          ]),
          _cache[0] || (_cache[0] = _createElementVNode("span", null, "Edit Search", -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "bg-theme-gray-7 px-5 py-4" }, [
            _createElementVNode("div", { class: "text-theme-gray-2 uppercase text-size-2" }, "Filter your Results")
          ], -1)),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-theme-gray-2 uppercase text-small" }, "Passengers", -1)),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(Dropdown, {
                  name: "passengers",
                  data: _unref(passengers),
                  onItem: handleDropItemSelected
                }, null, 8, ["data"])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-theme-gray-2 uppercase text-small" }, "Suitcases", -1)),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(Dropdown, {
                  name: "suitcases",
                  data: _unref(suitcases),
                  onItem: handleDropItemSelected
                }, null, 8, ["data"])
              ])
            ])
          ]),
          (_unref(authUser))
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[3] || (_cache[3] = [
                _createElementVNode("div", { class: "flex" }, [
                  _createElementVNode("div", { class: "ml-3 flex-1 md:flex md:justify-between" }, [
                    _createElementVNode("p", { class: "text-sm text-blue-700 font-medium" }, "The rates below have the commission percentage you have added. In order to get net rates, please set commission as 0 in your profile.")
                  ])
                ], -1)
              ])))
            : _createCommentVNode("", true),
          (isLoading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12))
            : (cars.value && cars.value.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredCars.value, (car, index) => {
                    return (_openBlock(), _createBlock(CarResult, {
                      key: index,
                      car: car,
                      canSelect: true,
                      selectedCar: selectedCar.value,
                      onSelected: handleCarSelected
                    }, null, 8, ["car", "selectedCar"]))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_14, _cache[4] || (_cache[4] = [
                  _createElementVNode("h3", { class: "mt-2 text-lg font-semibold text-gray-900" }, "No results", -1),
                  _createElementVNode("p", { class: "mt-1 text-sm text-gray-500" }, "Unfortunately we have found cars matching your criteria", -1)
                ]))),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createVNode(_component_font_awesome_icon, {
                  size: "xl",
                  icon: "fa-solid fa-cart-shopping",
                  class: "text-theme-active"
                })
              ]),
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "text-theme-gray-5 text-size-8 md:text-head-md-3" }, "Extra Options", -1)),
              _createElementVNode("div", {
                onClick: toggleExtraOptions,
                class: "flex flex-row items-center gap-3 group cursor-pointer"
              }, [
                _createElementVNode("div", _hoisted_18, [
                  _createVNode(_component_font_awesome_icon, {
                    size: "xs",
                    icon: "fa-solid fa-chevron-down",
                    class: "text-theme-gray-6"
                  })
                ]),
                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "uppercase text-theme-gray-2 text-size-2 tracking-wide group-hover:text-theme-gray-3" }, "Hide", -1))
              ])
            ]),
            _createElementVNode("div", _hoisted_19, [
              _cache[9] || (_cache[9] = _createStaticVNode("<div class=\"grid grid-cols-6 md:divide-x divide-theme-gray-7\"><div class=\"col-span-6 md:col-span-4 md:px-5 py-4 flex flex-col\"><div class=\"flex flex-col\"><div class=\"text-theme-gray-5 font-bold text-size-6\">Child Seat</div><div class=\"text-theme-gray-2 font-light text-size-4\">Baby car seat for children aged 0-36 months</div><div class=\"border border-theme-gray-7 p-4 mt-2\"><div class=\"uppercase text-theme-gray-2 text-small tracking-wide group-hover:text-theme-gray-3\">Notes</div><textarea class=\"outline-none w-full\" rows=\"4\"></textarea></div></div></div><div class=\"col-span-6 md:col-span-1 relative px-5 py-4 flex flex-col border border-theme-gray-7 md:border-0 md:items-center justify-center\"><div class=\"flex flex-col gap-2\"><div class=\"text-theme-gray-2 text-small uppercase\">Number</div><input class=\"w-[20px] outline-none text-theme-gray-5 text-size-6 font-bold\" value=\"1\"></div><div class=\"flex flex-col absolute top-1/2 right-0 transform -translate-y-1/2\"><div class=\"flex items-center justify-center w-[30px] h-[30px] text-theme-active text-size-6 bg-white border-b border-l border-t border-theme-gray-7 cursor-pointer hover:bg-theme-active hover:text-white transition-all ease-in-out duration-500\">+</div><div class=\"flex items-center justify-center w-[30px] h-[30px] text-theme-active text-head-md bg-white border-b border-l border-t border-theme-gray-7 cursor-pointer hover:bg-theme-active hover:text-white transition-all ease-in-out duration-500\">-</div></div></div><div class=\"col-span-6 md:col-span-1 md:px-5 py-4 flex flex-col items-center justify-center\"><div class=\"gap-2 uppercase rounded-full px-8 py-4 border-2 border-theme-gray-7 bg-theme-gray-7 hover:bg-white text-theme-gray-2 text-size-2 flex items-center justify-center cursor-pointer w-full md:w-auto md:ml-auto transition-all ease-in-out duration-500\"><span>Select</span><span></span></div></div></div>", 1)),
              _createElementVNode("div", _hoisted_20, [
                _cache[8] || (_cache[8] = _createStaticVNode("<div class=\"col-span-6 md:col-span-4 md:px-5 py-4 flex flex-col\"><div class=\"flex flex-col\"><div class=\"text-theme-gray-5 font-bold text-size-6\">Bouquet of Flowers</div><div class=\"text-theme-gray-2 font-light text-size-4\">A bouquet of seasonal flowers prepared by a local florist</div></div></div><div class=\"col-span-6 md:col-span-1 relative px-5 py-4 flex flex-col border border-theme-gray-7 md:border-0 md:items-center justify-center\"><div class=\"flex flex-col gap-2\"><div class=\"text-theme-gray-2 text-small uppercase\">Number</div><input class=\"w-[20px] outline-none text-theme-gray-5 text-size-6 font-bold\" value=\"1\"></div><div class=\"flex flex-col absolute top-1/2 right-0 transform -translate-y-1/2\"><div class=\"flex items-center justify-center w-[30px] h-[30px] text-theme-active text-size-6 bg-white border-b border-l border-t border-theme-gray-7 cursor-pointer hover:bg-theme-active hover:text-white transition-all ease-in-out duration-500\">+</div><div class=\"flex items-center justify-center w-[30px] h-[30px] text-theme-active text-head-md bg-white border-b border-l border-t border-theme-gray-7 cursor-pointer hover:bg-theme-active hover:text-white transition-all ease-in-out duration-500\">-</div></div></div>", 2)),
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("div", _hoisted_22, [
                    _cache[7] || (_cache[7] = _createElementVNode("span", null, "Select", -1)),
                    _createElementVNode("span", null, [
                      _createVNode(_component_font_awesome_icon, {
                        size: "lg",
                        icon: "fa-solid fa-check",
                        class: "text-white"
                      })
                    ])
                  ])
                ])
              ]),
              _cache[10] || (_cache[10] = _createStaticVNode("<div class=\"grid grid-cols-6 md:divide-x divide-theme-gray-7\"><div class=\"col-span-6 md:col-span-5 md:px-5 py-4 flex flex-col\"><div class=\"flex flex-col\"><div class=\"text-theme-gray-5 font-bold text-size-6\">Airport Assistance and Hostess Service</div><div class=\"text-theme-gray-2 font-light text-size-4\">One of our hostesses will accompany you throughout your stay at the airport until the departure of your aircraft</div></div></div><div class=\"col-span-6 md:col-span-1 md:px-5 py-4 flex flex-col\"><div class=\"gap-2 uppercase rounded-full px-8 py-4 border-2 border-theme-gray-7 bg-theme-gray-7 hover:bg-white text-theme-gray-2 text-size-2 flex items-center justify-center cursor-pointer w-full md:w-auto md:ml-auto transition-all ease-in-out duration-500\"><span>Select</span><span></span></div></div></div><div class=\"grid grid-cols-6 md:divide-x divide-theme-gray-7\"><div class=\"col-span-6 md:col-span-5 md:px-5 py-4 flex flex-col\"><div class=\"flex flex-col\"><div class=\"text-theme-gray-5 font-bold text-size-6\">Bodyguard Service</div><div class=\"text-theme-gray-2 font-light text-size-4\">Skilled, professional bodyguard for private and executive VIP protection will accompany you throughout the trip and stops</div></div></div><div class=\"col-span-6 md:col-span-1 md:px-5 py-4 flex flex-col\"><div class=\"gap-2 uppercase rounded-full px-8 py-4 border-2 border-theme-gray-7 bg-theme-gray-7 hover:bg-white text-theme-gray-2 text-size-2 flex items-center justify-center cursor-pointer w-full md:w-auto md:ml-auto transition-all ease-in-out duration-500\"><span>Select</span><span></span></div></div></div>", 2))
            ])
          ]),
          (cars.value && cars.value.length)
            ? (_openBlock(), _createElementBlock("div", {
                key: 4,
                ref_key: "bookingProceed",
                ref: bookingProceed,
                onClick: handleBookingProceed,
                class: _normalizeClass([selectedCar.value && !isBusy.value ? 'hover:bg-white hover:text-theme-active cursor-pointer' : 'cursor-not-allowed', "gap-4 uppercase mt-12 rounded-full px-8 py-4 border-2 border-theme-active bg-theme-active text-white text-size-2 flex items-center justify-center w-full md:w-auto md:ml-auto transition-all ease-in-out duration-500 opacity-0"])
              }, [
                _cache[11] || (_cache[11] = _createElementVNode("span", null, "CHECKOUT", -1)),
                _createElementVNode("span", null, [
                  _createVNode(_component_font_awesome_icon, {
                    size: "lg",
                    icon: "fa-solid fa-chevron-right"
                  })
                ])
              ], 2))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createVNode(Loader, { show: isLoading.value }, null, 8, ["show"])
  ], 64))
}
}

})