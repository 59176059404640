import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "space-y-10 divide-y divide-gray-900/10 py-10 px-4 sm:px-6 lg:px-24" }
const _hoisted_2 = { class: "grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3" }
const _hoisted_3 = { class: "bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2" }
const _hoisted_4 = { class: "px-4 py-6 sm:p-8" }
const _hoisted_5 = { class: "grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6" }
const _hoisted_6 = { class: "sm:col-span-3" }
const _hoisted_7 = { class: "mt-2" }
const _hoisted_8 = {
  key: 0,
  class: "mt-2 text-sm text-red-600"
}
const _hoisted_9 = { class: "flex items-center justify-between gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8" }

import { ref, onMounted } from 'vue'
import SiteSettings from '@/composables/SiteSettings'
import { handleApiError, isImageFile, isLoggedIn, resetErrors, saveAuthCredentials, truncateText, updateAuthUser } from '@/utils/common'
import { NotificationType } from '@/types'
import Notification from '@/widgets/Notification.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'Settings',
  setup(__props) {

const { loadSiteSettings, saveSiteSettings } = SiteSettings()
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const isLoading = ref<boolean>(true)
const cancellationHours = ref<number>(0)
const errors = ref<any>({
   cancellationHours: false
})

onMounted(async () => {

   await fetchSiteSettings()
})

const fetchSiteSettings = async () => {

   isLoading.value = true
   const response = await loadSiteSettings()
   isLoading.value = false

   if (response.status == 200) {
      cancellationHours.value = response.data.cancellation_hours
   } else {
      const error = handleApiError(response)
      notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
      setTimeout(() => { notifClosed() }, 4000)
   }
}

const handleSubmit = async () => {

   if (!isLoading.value) {

      resetErrors(errors.value)
      const isValid = validateForm()
   
      if (isValid) {
   
         isLoading.value = true
         let response : any

         response = await saveSiteSettings(cancellationHours.value)

         isLoading.value = false
         if (response.status == 200) {
            notif.value = { show: true, type: 'success', message: 'Success', messageDetails: 'Settings Updated'}
            setTimeout(() => { notifClosed() }, 4000)

            fetchSiteSettings()
         } else {
            const error = handleApiError(response)
            notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
            setTimeout(() => { notifClosed() }, 4000)
         } 
      }
   }
}

const notifClosed = () : void => {
   notif.value.show = false
}

const validateForm = () : boolean => {

   let response = true
   return response
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "px-4 sm:px-0" }, [
          _createElementVNode("h2", { class: "text-base font-semibold leading-7 text-gray-900" }, "Site Settings"),
          _createElementVNode("p", { class: "mt-1 text-sm leading-6 text-gray-600" }, "Configure commissions, discounts")
        ], -1)),
        _createElementVNode("form", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _cache[1] || (_cache[1] = _createElementVNode("label", {
                  for: "full-name",
                  class: "block text-sm font-medium leading-6 text-gray-900"
                }, "Free Cancellation Time *", -1)),
                _cache[2] || (_cache[2] = _createElementVNode("p", { class: "text-gray-500 text-xs" }, "Hours before customer can cancel their booking", -1)),
                _createElementVNode("div", _hoisted_7, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((cancellationHours).value = $event)),
                    type: "number",
                    id: "full-name",
                    class: _normalizeClass([errors.value.cancellationHours ? 'ring-red-600' : 'ring-gray-300', "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6 pl-2"]),
                    autocomplete: "given-name"
                  }, null, 2), [
                    [_vModelText, cancellationHours.value]
                  ])
                ]),
                (errors.value.cancellationHours)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_8, "This field is required"))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _cache[3] || (_cache[3] = _createElementVNode("p", { class: "mt-2 text-sm text-gray-900" }, "* required field", -1)),
            _createElementVNode("button", {
              onClick: handleSubmit,
              type: "button",
              class: _normalizeClass([isLoading.value ? 'opacity-70 cursor-not-allowed' : 'cursor-pointer', "rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 outlinne-none"])
            }, "Save", 2)
          ])
        ])
      ])
    ]),
    _createVNode(Notification, {
      show: notif.value.show,
      type: notif.value.type,
      message: notif.value.message,
      messageDetails: notif.value.messageDetails,
      onClosed: notifClosed
    }, null, 8, ["show", "type", "message", "messageDetails"])
  ], 64))
}
}

})