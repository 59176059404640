<template>
   <div class="space-y-10 divide-y divide-gray-900/10 py-10 px-4 sm:px-6 lg:px-24">
      <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
         <div class="px-4 sm:px-0">
            <h2 class="text-base font-semibold leading-7 text-gray-900">Account Information</h2>
            <p class="mt-1 text-sm leading-6 text-gray-600">Setup your personal info and commission</p>
         </div>
         <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
            <div class="px-4 py-6 sm:p-8">
               <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div class="sm:col-span-6">
                     <input class="hidden" ref="fileInput" type="file" @change="uploadMedia" accept="image/*">
                     <label for="photo" class="block text-sm font-medium leading-6 text-gray-900">
                        Photo
                        <span v-if="userAccount.personal.photo" class="text-red-600">({{ truncateText(userAccount.personal.photoName, 20) }})</span>
                     </label>
                     <div class="mt-2 flex items-center gap-x-3">
                        <img v-if="userAccount.personal.photo" class="h-8 w-8 rounded-full bg-gray-50" :src="userAccount.personal.photo" alt="">
                        <img v-else-if="userAccount.personal.photoName != ''" class="h-8 w-8 rounded-full bg-gray-50" :src="userAccount.personal.photoName" alt="">
                        <img v-else class="h-8 w-8 rounded-full bg-gray-50" src="/assets/avatars/default.jpg" alt="">
                        <button @click="handleChooseAvatar" type="button" class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">{{ userAccount.personal.photoName == '' ? 'Upload' : 'Change' }}</button>
                     </div>
                  </div>
                  <div class="sm:col-span-3">
                     <label for="full-name" class="block text-sm font-medium leading-6 text-gray-900">Full name *</label>
                     <div class="mt-2">
                        <input v-model="userAccount.personal.fullName" type="text" id="full-name" :class="errors.personal.fullName ? 'ring-red-600' : 'ring-gray-300'" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6 pl-2" />
                     </div>
                     <p v-if="errors.personal.fullName" class="mt-2 text-sm text-red-600">This field is required</p>
                  </div>
                  <div class="sm:col-span-3">
                     <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email</label>
                     <div class="mt-2">
                        <input v-model="userAccount.personal.email" type="text" readonly disabled id="email" autocomplete="given-email" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6 pl-2" />
                     </div>
                  </div>
                  <div class="sm:col-span-3">
                     <label for="full-name" class="block text-sm font-medium leading-6 text-gray-900">Phone *</label>
                     <div class="mt-2">
                        <input v-model="userAccount.personal.phone" type="text" id="phone" autocomplete="given-phone" :class="errors.personal.fullName ? 'ring-red-600' : 'ring-gray-300'" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6 pl-2" />
                     </div>
                     <p v-if="errors.personal.phone" class="mt-2 text-sm text-red-600">This field is required</p>
                  </div>
                  <div class="sm:col-span-3">
                     <label for="company-name" class="block text-sm font-medium leading-6 text-gray-900">Company Name *</label>
                     <div class="mt-2">
                        <input v-model="userAccount.personal.company" type="text" id="company-name" :class="errors.personal.fullName ? 'ring-red-600' : 'ring-gray-300'" autocomplete="given-company" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6 pl-2" />
                     </div>
                     <p v-if="errors.personal.company" class="mt-2 text-sm text-red-600">This field is required</p>
                  </div>
                  <div v-if="authUser.user.type == 'agent' && authUser.user.sub_type == null" class="sm:col-span-3">
                     <label for="company-name" class="block text-sm font-medium leading-6 text-gray-900">Choose Your Commission (%age)</label>
                     <div class="mt-2">
                        <select v-model="selectedCommissionOption" class="block mt-1 ring-1 ring-gray-300 rounded-sm bg-white w-full rounded-md border-0 py-1.5 text-gray-900 outline-none h-10 sm:max-w-xs sm:text-sm sm:leading-6">
                           <option v-for="(item, index) in commissionOptions" :key="index" :value="item.value">{{ item.name }}</option>
                        </select>
                     </div>
                  </div>
                  <div v-if="authUser.user.type == 'agent' && authUser.user.sub_type == null" class="sm:col-span-3" :style="commissionInputStyle">
                     <label for="company-name" class="block text-sm font-medium leading-6 text-gray-900">Enter Your Commission (%age)</label>
                     <div class="mt-2">
                        <input v-model="userAccount.personal.commission" type="text" id="company-name" :class="errors.personal.commission ? 'ring-red-600' : 'ring-gray-300'" autocomplete="given-company" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6 pl-2" />
                     </div>
                     <p v-if="errors.personal.commission" class="mt-2 text-sm text-red-600">{{errors.personal.commission}}</p>
                  </div>
               </div>
            </div>
            <div class="flex items-center justify-between gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
               <p class="mt-2 text-sm text-gray-900">* required field</p>
               <button @click="handlePersonalSubmit" type="button" :class="isLoading ? 'opacity-70 cursor-not-allowed' : 'cursor-pointer'" class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 outlinne-none">Save</button>
            </div>
         </form>
      </div>
      <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
         <div class="px-4 sm:px-0">
            <h2 class="text-base font-semibold leading-7 text-gray-900">Update Password</h2>
            <p class="mt-1 text-sm leading-6 text-gray-600">You can update your account password</p>
         </div>
         <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
            <div class="px-4 py-6 sm:p-8">
               <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div class="sm:col-span-6">
                     <label for="current-password" class="block text-sm font-medium leading-6 text-gray-900">Current password</label>
                     <div class="mt-2">
                        <input v-model="userAccount.password.current" type="password" id="current-password" autocomplete="given-current-password" :class="errors.password.current ? 'ring-red-600' : 'ring-gray-300'" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6 pl-2" />
                     </div>
                     <p v-if="errors.password.current" class="mt-2 text-sm text-red-600">This field is required</p>
                  </div>
                  <div class="sm:col-span-3">
                     <label for="new-password" class="block text-sm font-medium leading-6 text-gray-900">New password</label>
                     <div class="mt-2">
                        <input v-model="userAccount.password.new" type="password" id="new-password" autocomplete="given-new-password" :class="errors.password.new ? 'ring-red-600' : 'ring-gray-300'" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6 pl-2" />
                     </div>
                     <p v-if="errors.password.new" class="mt-2 text-sm text-red-600">{{ errors.password.new }}</p>
                  </div>
                  <div class="sm:col-span-3">
                     <label for="password-confirmation" class="block text-sm font-medium leading-6 text-gray-900">Confirm password</label>
                     <div class="mt-2">
                        <input v-model="userAccount.password.confirmNew" type="password" id="password-confirmation" autocomplete="given-new-password-confirm" :class="errors.password.confirmNew ? 'ring-red-600' : 'ring-gray-300'" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6 pl-2" />
                     </div>
                     <p v-if="errors.password.confirmNew" class="mt-2 text-sm text-red-600">{{ errors.password.confirmNew }}</p>
                  </div>
               </div>
            </div>
            <div class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
               <button @click="handlePasswordSubmit" type="button" :class="isLoading ? 'opacity-70 cursor-not-allowed' : 'cursor-pointer'" class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 outlinne-none">Save</button>
            </div>
         </form>
      </div>
   </div>
   <Notification :show="notif.show" :type="notif.type" :message="notif.message" :messageDetails="notif.messageDetails" @closed="notifClosed"/>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, computed } from 'vue'
import Agent from '@/composables/admin/agent'
import Corporate from '@/composables/admin/corporate'
import { handleApiError, isImageFile, isLoggedIn, resetErrors, saveAuthCredentials, truncateText, updateAuthUser } from '@/utils/common'
import { NotificationType } from '@/types'
import Notification from '@/widgets/Notification.vue'

const props = defineProps(['userType'])
const authUser = isLoggedIn()
const { agentProfile, agentProfileSave } = Agent()
const { corporateProfile, corporateProfileSave } = Corporate()
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const isLoading = ref<boolean>(true)
const fileInput = ref<HTMLInputElement | null>(null)
const commissionOptions = ref<any>([
   {name: '5%', value: 5},
   {name: '10%', value: 10},
   {name: '15%', value: 15},
   {name: '20%', value: 20},
   {name: '25%', value: 25},
   {name: 'other', value: 'other'},
])
const selectedCommissionOption = ref<any>(commissionOptions.value[0].value)
const commissionInputStyle = computed(() => ({ opacity: selectedCommissionOption.value === 'other' ? '1' : '0' }))
const userAccount = ref<any>({
   personal: {
      fullName: '',
      email: '',
      photo: null,
      photoName: '',
      commission: 0,
      phone: '',
      company: ''
   },
   password: {
      current: '',
      new: '',
      confirmNew: ''
   }
})
const errors = ref<any>({
   personal: {
      fullName: null,
      commission: null,
      phone: null,
      company: null
   },
   password: {
      current: null,
      new: null,
      confirmNew: null
   }
})

watch(selectedCommissionOption, (newValue: any, oldValue: any) => {
   if (newValue != 'other') {
      userAccount.value.personal.commission = newValue
   }
})

onMounted(async () => {

   if (authUser && authUser.user.type == 'agent') {

      await fetchAgentProfile()
   } else if (authUser && authUser.user.type == 'corporate'){

      await fetchCorporateProfile()
   }
})

const fetchAgentProfile = async () => {

   isLoading.value = true
   const response = await agentProfile()
   isLoading.value = false

   if (response.status == 200) {
      userAccount.value.personal = {
         fullName: response.data.data.name,
         email: response.data.data.email,
         photoName: response.data.data.photo,
         commission: response.data.data.commission,
         phone: response.data.data.phone,
         company: response.data.data.company
      }

      const matchingOption = commissionOptions.value.find((option: any) => option.value == response.data.data.commission)
      selectedCommissionOption.value = matchingOption ? matchingOption.value : commissionOptions.value[commissionOptions.value.length - 1].value
   } else {
      const error = handleApiError(response)
      notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
      setTimeout(() => { notifClosed() }, 4000)
   }
}

const fetchCorporateProfile = async () => {

   isLoading.value = true
   const response = await corporateProfile()
   isLoading.value = false

   if (response.status == 200) {
      userAccount.value.personal = {
         fullName: response.data.data.name,
         email: response.data.data.email,
         photoName: response.data.data.photo,
         commission: response.data.data.commission,
         phone: response.data.data.phone,
         company: response.data.data.company
      }
   } else {
      const error = handleApiError(response)
      notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
      setTimeout(() => { notifClosed() }, 4000)
   }
}

const handlePersonalSubmit = async () => {

   if (!isLoading.value) {

      resetErrors(errors.value)
      const isValid = validateForm('personal')
   
      if (isValid) {
   
         isLoading.value = true
         let response : any

         if (authUser && authUser.user.type == 'agent') {

            response = await agentProfileSave(userAccount.value.personal, 'personal')
         } else if (authUser && authUser.user.type == 'corporate') {

            response = await corporateProfileSave(userAccount.value.personal, 'personal')
         }

         isLoading.value = false
         if (response.status == 200) {
            notif.value = { show: true, type: 'success', message: 'Success', messageDetails: 'Profile updated'}
            setTimeout(() => { notifClosed() }, 4000)
            
            if (authUser && authUser.user.type == 'agent') {

               await fetchAgentProfile()
            } else if (authUser && authUser.user.type == 'corporate') {

               await fetchCorporateProfile()
            }

            updateAuthUser(response.data.user)
         } else {
            const error = handleApiError(response)
            notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
            setTimeout(() => { notifClosed() }, 4000)
         } 
      }
   }
}

const handlePasswordSubmit = async () => {

   if (!isLoading.value) {

      resetErrors(errors.value)
      const isValid = validateForm('password')
   
      if (isValid) {
   
         isLoading.value = true
         let response : any

         if (authUser && authUser.user.type == 'agent') {

            response = await agentProfileSave(userAccount.value.password, 'password')
         } else if (authUser && authUser.user.type == 'corporate') {

            response = await corporateProfileSave(userAccount.value.password, 'password')
         }

         isLoading.value = false
         if (response.status == 200) {
            userAccount.value.password = {
               current: null,
               new: null,
               confirmNew: null
            }
            notif.value = { show: true, type: 'success', message: 'Success', messageDetails: 'Password updated'}
            setTimeout(() => { notifClosed() }, 4000)
         } else {
            const error = handleApiError(response)
            notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
            setTimeout(() => { notifClosed() }, 4000)
         }
      }
   }
}

const notifClosed = () : void => {
   notif.value.show = false
}

const validateForm = (type: string) : boolean => {

   let response = true

   if (type == 'personal') {

      if (userAccount.value.personal.fullName == '') {
         errors.value.personal.fullName = true
         response = false
      }
      if (userAccount.value.personal.commission < 0 || userAccount.value.personal.commission > 50) {
         errors.value.personal.commission = 'Invalid value. It cannot be more than 50'
         response = false
      }
      if (userAccount.value.personal.phone == '') {
         errors.value.personal.phone = true
         response = false
      }
      if (userAccount.value.personal.company == '') {
         errors.value.personal.company = true
         response = false
      }
   } else if (type == 'password'){

      if (userAccount.value.password.current == '') {
         errors.value.password.current = true
         response = false
      }

      if (userAccount.value.password.new == '') {
         errors.value.password.new = 'This field is required'
         response = false
      } else if (userAccount.value.password.new.length < 6) {
         errors.value.password.new = 'Password must be at least 6 characters long'
         response = false
      }

      if (userAccount.value.password.confirmNew == '') {
         errors.value.password.confirmNew = 'This field is required'
         response = false
      } else if (userAccount.value.password.new != '' && userAccount.value.password.new != userAccount.value.password.confirmNew){
         errors.value.password.confirmNew = 'Confirm password must be the same as new password'
         response = false
      }
   }

   return response
}

const handleChooseAvatar = () => {
   fileInput.value?.click()
}

const uploadMedia = (e: Event) => {

   userAccount.value.personal.photo = null
   userAccount.value.personal.photoName = ''
   const inputElement = e.target as HTMLInputElement

   if (!inputElement.files || inputElement.files.length === 0) {
      return;
   }

   const file = inputElement.files[0]
   const reader = new FileReader()

   if (!isImageFile(file)) {
      notif.value = { show: true, type: 'error', message: 'Error', messageDetails: 'File must be an image of type JPG or PNG'}
   } else if (file.size > 3145728) {
      notif.value = { show: true, type: 'error', message: 'Error', messageDetails: 'Image must be 3MB or less'}
   }

   userAccount.value.personal.photoName = file.name

   reader.onloadend = () => {
      userAccount.value.personal.photo = reader.result as string
   }

   reader.readAsDataURL(file)
}

</script>