
import Car from "@/composables/Car"
import { Locality, UserSearch, Car as CarData, PaymentDetails } from "@/types"
import { getSessionPrefix, handleApiError } from "@/utils/common"
import BookingCredit from "@/composables/BookingCredit"

const { getCreditInfo, withdrawFromCredit }  = BookingCredit()

const BookingCreditService = () => {

   const getCredit : any = async (bookingNumber: number) => {
      try {

         const response = await getCreditInfo(bookingNumber)

         if (response.status === 200) {
            return { success: true, data: response.data }
         } else {
            return { success: false, error: handleApiError(response) }
         }
      } catch (err) {
         return { success: false, error: err }
      }
   }
   
   const withdrawFromCreditt : any = async (bookingNumber: number) => {
      try {

         const response = await withdrawFromCredit(bookingNumber)

         if (response.status === 200) {
            return { success: true, data: response.data }
         } else {
            return { success: false, error: handleApiError(response) }
         }
      } catch (err) {
         return { success: false, error: err }
      }
   }

   return {
      getCredit,
      withdrawFromCreditt
   }
}

export default BookingCreditService
