import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "sticky top-0 z-40 flex md:h-16 shrink-0 items-center md:gap-x-4 border-b border-gray-200 bg-white md:px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8" }
const _hoisted_2 = { class: "px-4 sm:px-6 lg:px-8" }
const _hoisted_3 = { class: "flex h-16 justify-between" }
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { class: "-ml-2 mr-2 flex items-center md:hidden" }
const _hoisted_6 = { class: "hidden md:ml-6 md:flex md:space-x-8" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "flex items-center" }
const _hoisted_9 = { class: "flex-shrink-0" }
const _hoisted_10 = { class: "hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center" }
const _hoisted_11 = {
  type: "button",
  class: "relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-theme-active focus:ring-offset-2"
}
const _hoisted_12 = ["src"]
const _hoisted_13 = {
  key: 1,
  class: "h-8 w-8 rounded-full bg-gray-50",
  src: "/assets/avatars/default.jpg",
  alt: ""
}
const _hoisted_14 = { class: "space-y-1 pb-3 pt-2" }
const _hoisted_15 = { class: "border-t border-gray-200 pb-3 pt-4" }
const _hoisted_16 = { class: "flex items-center px-4 sm:px-6" }
const _hoisted_17 = { class: "flex-shrink-0" }
const _hoisted_18 = ["src"]
const _hoisted_19 = {
  key: 1,
  class: "h-8 w-8 rounded-full bg-gray-50",
  src: "/assets/avatars/default.jpg",
  alt: ""
}
const _hoisted_20 = { class: "ml-3" }
const _hoisted_21 = { class: "text-base font-medium text-gray-800" }
const _hoisted_22 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_23 = {
  type: "button",
  class: "relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
}
const _hoisted_24 = { class: "mt-3 space-y-1" }

import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { useRoute, useRouter } from 'vue-router'
import { isAdminLoggedIn, isLoggedIn, logout } from '@/utils/common'
import { ref, onMounted } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'TopBar',
  emits: ['openSidebar'],
  setup(__props, { emit: __emit }) {

const router = useRouter()
const emit = __emit
const auth = isLoggedIn()
const route = useRoute()
const adminAuth = isAdminLoggedIn()
const authUser = auth ? auth.user : adminAuth.admin
let navigation : any = []
const userNavigation = [
   { name: 'Log out' },
]

if (auth) {

   if (auth.user.type == 'agent') {

      if (auth.user.sub_type == null) {
         navigation = [
            { name: 'Dashboard', route: router.resolve({ name: 'AccountDashboard' }).href, current: route.name == 'AccountDashboard' },
            { name: 'Bookings', route: router.resolve({ name: 'UserBookings', params: {userType: 'agent'} }).href, current: route.name == 'UserBookings' },
            { name: 'Sub Agents', route: router.resolve({ name: 'SubAgents' }).href, current: route.name == 'SubAgents' },
            { name: 'Reports', route: router.resolve({ name: 'AgentReports' }).href, current: route.name == 'AgentReports' },
         ]
      } else {
         navigation = [
         { name: 'Dashboard', route: router.resolve({ name: 'AccountDashboard' }).href, current: route.name == 'AccountDashboard' },
            { name: 'Bookings', route: router.resolve({ name: 'UserBookings', params: {userType: 'agent'} }).href, current: route.name == 'UserBookings' },
         ]
      }
   } else if (auth.user.type == 'corporate') {
      if (auth.user.sub_type == null) {
         navigation = [
            { name: 'Dashboard', route: router.resolve({ name: 'AccountDashboard' }).href, current: route.name == 'AccountDashboard' },
            { name: 'Bookings', route: router.resolve({ name: 'UserBookings', params: {userType: 'corporate'} }).href, current: route.name == 'UserBookings' },
            { name: 'Sub Users', route: router.resolve({ name: 'SubUsers' }).href, current: route.name == 'SubUsers' }
         ]
      } else {
         navigation = [
            { name: 'Dashboard', route: router.resolve({ name: 'AccountDashboard' }).href, current: route.name == 'AccountDashboard' },
            { name: 'Bookings', route: router.resolve({ name: 'UserBookings', params: {userType: 'corporate'} }).href, current: route.name == 'UserBookings' },
         ]
      }
   }
} else if (adminAuth) {

   navigation = [
      { name: 'Dashboard', route: router.resolve({ name: 'AdminDashboard' }).href, current: route.name == 'AdminDashboard' },
      { name: 'Bookings', route: router.resolve({ name: 'AdminBookings' }).href, current: route.name == 'AdminBookings' },
      { name: 'Cars', route: router.resolve({ name: 'AdminCars' }).href, current: route.name == 'AdminCars' },
      { name: 'Pages', route: router.resolve({ name: 'AdminCustomPage' }).href, current: route.name == 'AdminCustomPage' },
      { name: 'Agents', route: router.resolve({ name: 'AdminAgents' }).href, current: route.name == 'AdminAgents' },
      { name: 'Reports', route: router.resolve({ name: 'AdminReports' }).href, current: route.name == 'AdminReports' },
   ]
}

const handleLogout = () => {
   logout(router)
}

const handleNavClick = (item: any) => {

   router.push(item.route)
}

const navigateSettings = () => {

   if (authUser.type == 'admin') {
      router.push({name: 'AdminSettings'})
   } else {
      router.push({name: 'UserSettings', params: {userType: authUser.type}})
   }
}


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Disclosure), {
      as: "nav",
      class: "bg-white w-full"
    }, {
      default: _withCtx(({ open }) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_unref(DisclosureButton), { class: "relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-theme-active" }, {
                  default: _withCtx(() => [
                    _cache[2] || (_cache[2] = _createElementVNode("span", { class: "absolute -inset-0.5" }, null, -1)),
                    _cache[3] || (_cache[3] = _createElementVNode("span", { class: "sr-only" }, "Open main menu", -1)),
                    (!open)
                      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                          key: 0,
                          size: "lg",
                          icon: "fa-solid fa-bars"
                        }))
                      : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                          key: 1,
                          icon: "fa-solid fa-xmark"
                        }))
                  ]),
                  _: 2
                }, 1024)
              ]),
              _createElementVNode("div", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push({name: 'Home'}))),
                class: "flex flex-shrink-0 items-center cursor-pointer"
              }, _cache[4] || (_cache[4] = [
                _createElementVNode("img", {
                  class: "h-8 w-auto",
                  src: "/assets/logo.png",
                  alt: "Vehitra World"
                }, null, -1)
              ])),
              _createElementVNode("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(navigation), (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    onClick: ($event: any) => (handleNavClick(item)),
                    key: item.name,
                    class: _normalizeClass(["cursor-pointer inline-flex items-center px-1 pt-1 text-sm font-bold", _unref(route).path != item.route ? 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700' : 'text-gray-900 border-b-2 border-theme-active'])
                  }, _toDisplayString(item.name), 11, _hoisted_7))
                }), 128))
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("button", {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push({name: 'Home'}))),
                  type: "button",
                  class: "relative inline-flex items-center gap-x-1.5 rounded-md bg-theme-active px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-active"
                }, [
                  _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-plus" }),
                  _cache[5] || (_cache[5] = _createTextVNode(" Create Booking "))
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("button", _hoisted_11, [
                  _cache[6] || (_cache[6] = _createElementVNode("span", { class: "absolute -inset-1.5" }, null, -1)),
                  _cache[7] || (_cache[7] = _createElementVNode("span", { class: "sr-only" }, "View notifications", -1)),
                  _createVNode(_component_font_awesome_icon, {
                    size: "lg",
                    icon: "fa-regular fa-bell",
                    class: ""
                  })
                ]),
                _createVNode(_unref(Menu), {
                  as: "div",
                  class: "relative ml-3"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createVNode(_unref(MenuButton), { class: "relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-theme-active focus:ring-offset-2" }, {
                        default: _withCtx(() => [
                          _cache[8] || (_cache[8] = _createElementVNode("span", { class: "absolute -inset-1.5" }, null, -1)),
                          _cache[9] || (_cache[9] = _createElementVNode("span", { class: "sr-only" }, "Open user menu", -1)),
                          (_unref(authUser).photo)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                class: "h-8 w-8 rounded-full bg-gray-50",
                                src: _unref(authUser).photo,
                                alt: ""
                              }, null, 8, _hoisted_12))
                            : (_openBlock(), _createElementBlock("img", _hoisted_13))
                        ]),
                        _: 1
                      })
                    ]),
                    _createVNode(_Transition, {
                      "enter-active-class": "transition ease-out duration-200",
                      "enter-from-class": "transform opacity-0 scale-95",
                      "enter-to-class": "transform opacity-100 scale-100",
                      "leave-active-class": "transition ease-in duration-75",
                      "leave-from-class": "transform opacity-100 scale-100",
                      "leave-to-class": "transform opacity-0 scale-95"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(MenuItems), { class: "absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(MenuItem), null, {
                              default: _withCtx(({ active }) => [
                                _createElementVNode("div", {
                                  onClick: navigateSettings,
                                  class: _normalizeClass([active ? 'bg-gray-100' : '', 'cursor-pointer block px-4 py-2 text-sm text-gray-700'])
                                }, "Settings", 2)
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(MenuItem), null, {
                              default: _withCtx(({ active }) => [
                                _createElementVNode("div", {
                                  onClick: handleLogout,
                                  class: _normalizeClass([active ? 'bg-gray-100' : '', 'cursor-pointer block px-4 py-2 text-sm text-gray-700'])
                                }, "Sign out", 2)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ])
            ])
          ])
        ]),
        _createVNode(_unref(DisclosurePanel), { class: "md:hidden" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_14, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(navigation), (item) => {
                return (_openBlock(), _createBlock(_unref(DisclosureButton), {
                  onClick: ($event: any) => (handleNavClick(item)),
                  key: item.name,
                  as: "div",
                  class: _normalizeClass(["block border-l-4 py-2 pl-3 pr-4 text-base font-medium sm:pl-5 sm:pr-6", _unref(route).path != item.route ? 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700' : 'bg-theme-active border-theme-active text-white'])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.name), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick", "class"]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  (_unref(authUser).photo)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        class: "h-8 w-8 rounded-full bg-gray-50",
                        src: _unref(authUser).photo,
                        alt: ""
                      }, null, 8, _hoisted_18))
                    : (_openBlock(), _createElementBlock("img", _hoisted_19))
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, _toDisplayString(_unref(authUser).name ? _unref(authUser).name : (_unref(authUser).firstName + ' ' + _unref(authUser).lastName)), 1),
                  _createElementVNode("div", _hoisted_22, _toDisplayString(_unref(authUser).email ? _unref(authUser).email : ''), 1)
                ]),
                _createElementVNode("button", _hoisted_23, [
                  _cache[10] || (_cache[10] = _createElementVNode("span", { class: "absolute -inset-1.5" }, null, -1)),
                  _cache[11] || (_cache[11] = _createElementVNode("span", { class: "sr-only" }, "View notifications", -1)),
                  _createVNode(_component_font_awesome_icon, {
                    size: "lg",
                    icon: "fa-regular fa-bell",
                    class: ""
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_24, [
                (_unref(authUser).type != 'admin')
                  ? (_openBlock(), _createBlock(_unref(DisclosureButton), {
                      key: 0,
                      onClick: navigateSettings,
                      as: "div",
                      class: "block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6"
                    }, {
                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                        _createTextVNode("Settings")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_unref(DisclosureButton), {
                  onClick: handleLogout,
                  as: "div",
                  class: "block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6"
                }, {
                  default: _withCtx(() => _cache[13] || (_cache[13] = [
                    _createTextVNode("Sign out")
                  ])),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})