import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col bg-theme-gray-1 px-4 py-8" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-col divide-y divide-theme-gray-9 gap-5"
}
const _hoisted_3 = { class: "flex flex-col gap-2 pt-4" }
const _hoisted_4 = { class: "text-theme-gray-5 text-size-3 capitalize" }
const _hoisted_5 = { class: "hidden flex flex-col gap-2 pt-4" }
const _hoisted_6 = { class: "text-theme-gray-5 text-size-3 capitalize" }
const _hoisted_7 = { class: "flex flex-col gap-2 pt-4" }
const _hoisted_8 = {
  key: 0,
  class: "flex flex-col gap-2 pt-4"
}
const _hoisted_9 = { class: "text-theme-gray-5 text-size-3" }
const _hoisted_10 = { class: "flex flex-col gap-2 pt-4" }
const _hoisted_11 = { class: "text-theme-gray-5 text-size-3" }
const _hoisted_12 = {
  key: 1,
  class: "flex flex-row items-start pt-4 gap-2"
}
const _hoisted_13 = { class: "flex-1 flex flex-col gap-2" }
const _hoisted_14 = { class: "text-theme-gray-5 text-size-3" }
const _hoisted_15 = { class: "flex-1 flex flex-col gap-2" }
const _hoisted_16 = { class: "text-theme-gray-5 text-size-3" }
const _hoisted_17 = {
  key: 2,
  class: "flex flex-row items-start pt-4 gap-2"
}
const _hoisted_18 = { class: "flex-1 flex flex-col gap-2" }
const _hoisted_19 = { class: "text-theme-gray-5 text-size-3" }
const _hoisted_20 = {
  key: 3,
  class: "flex flex-col gap-2 pt-4"
}
const _hoisted_21 = { class: "text-theme-gray-5 text-size-3" }
const _hoisted_22 = {
  key: 0,
  class: "text-theme-active font-bold text-size-4"
}

import { onMounted, ref } from 'vue'
import { retrieveUserSearch } from '@/services/search-service' 
import { UserSearch, Car as CarData, LocationSchedule } from '@/types'
import { getSessionPrefix } from '@/utils/common'
import GoogleMap from '@/components/GoogleMap.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'BookingSummary',
  props: ['currentStep'],
  setup(__props) {

const props = __props
const userSearch = ref<UserSearch | null>(null)
const selectedCar = ref<CarData | null>(null)
const distance = ref<string>('0 km')
const time = ref<string>('0h 0m')

const handleMapData = (value: string, type: string) => {
   if (type == 'distance') {
      distance.value = value
   } else if(type == 'time') {
      time.value = value
   }
}

const handlePickUpClick = () => {
 
   const locationName = userSearch.value?.pickUp.location.name as string
   const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(locationName)}`
   window.open(googleMapsUrl, '_blank')
}  

onMounted(() => {

   userSearch.value = retrieveUserSearch()
   selectedCar.value = retrieveSelectedVehicle()
})

const retrieveSelectedVehicle = () : CarData => {

   const prefix = getSessionPrefix()
   const carString = localStorage.getItem(`${prefix}_selected_car`)

   return carString ? JSON.parse(carString) : null
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[9] || (_cache[9] = _createElementVNode("div", { class: "text-theme-gray-5 text-size-6 font-bold mb-3" }, "Summary", -1)),
    (userSearch.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-theme-gray-2 uppercase text-small" }, "Service type", -1)),
            _createElementVNode("div", _hoisted_4, _toDisplayString(userSearch.value.type), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-theme-gray-2 uppercase text-small" }, "Transfer type", -1)),
            _createElementVNode("div", _hoisted_6, _toDisplayString(userSearch.value.mode), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-theme-gray-2 uppercase text-small" }, "Pickup location", -1)),
            _createElementVNode("div", {
              onClick: handlePickUpClick,
              class: "text-theme-gray-5 text-size-3 hover:underline cursor-pointer"
            }, _toDisplayString(userSearch.value.pickUp.location.name), 1),
            _createVNode(GoogleMap, {
              origin: userSearch.value.pickUp.location.id,
              destination: userSearch.value.dropOff.location.id,
              driveType: userSearch.value.type,
              onDistance: handleMapData,
              onTime: handleMapData
            }, null, 8, ["origin", "destination", "driveType"])
          ]),
          (userSearch.value.type == 'distance')
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _cache[3] || (_cache[3] = _createElementVNode("div", { class: "text-theme-gray-2 uppercase text-small" }, "Drop-off location", -1)),
                _createElementVNode("div", _hoisted_9, _toDisplayString(userSearch.value.dropOff.location.name), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_10, [
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-theme-gray-2 uppercase text-small" }, "Pickup date & time", -1)),
            _createElementVNode("div", _hoisted_11, _toDisplayString(userSearch.value.pickUp.date) + ", " + _toDisplayString(userSearch.value.pickUp.time), 1)
          ]),
          (userSearch.value.type == 'distance')
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-theme-gray-2 uppercase text-small" }, "Total distance", -1)),
                  _createElementVNode("div", _hoisted_14, _toDisplayString(distance.value), 1)
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _cache[6] || (_cache[6] = _createElementVNode("div", { class: "text-theme-gray-2 uppercase text-small" }, "Total time", -1)),
                  _createElementVNode("div", _hoisted_16, _toDisplayString(time.value), 1)
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _cache[7] || (_cache[7] = _createElementVNode("div", { class: "text-theme-gray-2 uppercase text-small" }, "No of Days", -1)),
                  _createElementVNode("div", _hoisted_19, _toDisplayString((userSearch.value.hours ?? 0) / 10) + " day(s)", 1)
                ])
              ])),
          (selectedCar.value && __props.currentStep?.uri == 'contact-details')
            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                _cache[8] || (_cache[8] = _createElementVNode("div", { class: "text-theme-gray-2 uppercase text-small" }, "Vehicle selected", -1)),
                _createElementVNode("div", _hoisted_21, _toDisplayString(selectedCar.value.name), 1),
                (selectedCar.value.price && selectedCar.value.price > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_22, "$" + _toDisplayString(selectedCar.value.price.toFixed(2)), 1))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})