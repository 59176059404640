import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "py-10 px-4 sm:px-6 lg:px-24" }
const _hoisted_2 = { class: "mt-8 flow-root" }
const _hoisted_3 = { class: "-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" }
const _hoisted_4 = { class: "inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8" }
const _hoisted_5 = { class: "overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg" }

import { ref, onMounted } from 'vue'
import BookingsTable from '@/components/account/BookingsTable.vue'
import Agent from '@/composables/admin/agent';
import Corporate from '@/composables/admin/corporate';
import { handleApiError, isAdminLoggedIn, isLoggedIn, logout } from '@/utils/common';
import router from '@/router';


export default /*@__PURE__*/_defineComponent({
  __name: 'Bookings',
  props: ['subUserId'],
  setup(__props) {

const { allAgentBookings, subAgentBookings, subAgents } = Agent()
const { allCorporateBookings } = Corporate()
const props = __props 
const isLoading = ref<boolean>(true)
const bookings = ref<any>(null)
const mySubAgents = ref([])

onMounted(async () => {

   const authUser = isLoggedIn()
   await fetchBookings(authUser ? authUser.user.type : '')
})

const fetchBookings = async (userType: string) => {

   isLoading.value = true
   let response : any

   if (userType == 'corporate') {

      response = await allCorporateBookings()
   } else if (userType == 'agent') {

      response = await allAgentBookings()
      await fetchSubAgents()
   }

   isLoading.value = false

   if (response.status == 200) {
      
      bookings.value = response.data
   } else if (response.response && response.response.status == 401) {
      logout(router)
   }
}

const fetchSubAgents = async () => {

   const response = await subAgents()

   if (response.status == 200) {
      mySubAgents.value = response.data
   } else {
      const error = handleApiError(response)
      console.log(error)
   }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "sm:flex sm:items-center" }, [
      _createElementVNode("div", { class: "sm:flex-auto" }, [
        _createElementVNode("h1", { class: "text-base font-semibold leading-6 text-gray-900" }, "Bookings"),
        _createElementVNode("p", { class: "mt-2 text-sm text-gray-700" }, "A list of all the bookings created under your account")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(BookingsTable, {
              subAgents: mySubAgents.value,
              bookings: bookings.value,
              isLoading: isLoading.value
            }, null, 8, ["subAgents", "bookings", "isLoading"])
          ])
        ])
      ])
    ])
  ]))
}
}

})