<template>

   <nav v-if="breadcrump" class="flex" aria-label="Breadcrumb">
      <ol role="list" class="px-4 sm:px-6 lg:px-24 flex items-center space-x-4">
         <li>
            <div>
               <div @click="router.push({name: 'Home'})" class="text-gray-400 :hover:text-gray-500 cursor-pointer">
                  <font-awesome-icon size="lg" icon="fa-solid fa-home" class="text-gray-500" />
                  <span class="sr-only">Home</span>
               </div>
            </div>
         </li>
         <li v-for="item in breadcrump" :key="item.title">
            <div class="flex items-center">
               <svg class="h-5 w-5 flex-shrink-0 text-gray-300" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
               </svg>
               <div @click="item.link ? router.push(item.link) : null" :class="item.link ? 'cursor-pointer hover:text-gray-500' : ''" class="ml-4 text-sm font-medium text-gray-500">{{ item.title }}</div>
            </div>
         </li>
      </ol>
   </nav>
   <div class="py-10 px-4 sm:px-6 lg:px-24">
      <main class="flex flex-col gap-12">
         <div>
            <div class="flex flex-wrap items-center justify-end gap-6 sm:flex-nowrap mb-6">
               <div class="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:leading-7">
                  <div v-for="item in scopes" :key="item.id" :class="item.isCurrent ? 'text-theme-active' : 'text-gray-700'" class="cursor-pointer">
                     <span v-if="!item.id.includes('custom')" @click="handleScopeUpdate(item)">{{ item.name }}</span>
                     <flat-pickr v-else v-model="dates" :config="dateRangeConfig" :class="item.isCurrent ? 'text-theme-gray-5' : 'text-theme-active'" class="border px-3 outline-none" placeholder="Custom date range" />
                  </div>
               </div>
            </div>

            <div v-if="auth && auth.user && auth.user.type == 'agent' && auth.user.sub_type == null && loyaltyData" class="flex flex-col w-full">
               <h3 class="text-base font-semibold leading-6 text-gray-900">Loyalty Program Points</h3>
               <div class="flex items-center">
                  <h5 class="text-gray-500 mt-1 text-sm">You have earned {{ loyaltyData.points_earned }} points. Earn at least {{ loyaltyData.total_points }} points to win a cashback gift from vehitra</h5>
                  <button v-if="loyaltyData.points_earned >= loyaltyData.total_points && loyaltyData.has_redeemed == 0" @click="handleRedeemPoints" class="ml-auto bg-theme-button2-bg text-theme-button2-txt p-2 text-sm rounded">Redeem Points</button>
                  <button v-else-if="loyaltyData.has_redeemed == 1" class="ml-auto cursor-auto bg-theme-button1-bg text-theme-button1-txt p-2 text-sm rounded">Points Redeemed</button>
               </div>
               <dl class="mt-5 grid grid-cols-1 gap-5">
                  <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 col-span-1">
                     <div class="bg-blue-600 h-2.5 rounded-full max-w-full" :style="{ width: `${(loyaltyData.points_earned/loyaltyData.total_points) * 100}%` }"></div>
                  </div>
               </dl>
            </div>

            <h3 class="text-base font-semibold leading-6 text-gray-900 mt-12">Bookings Summary</h3>
            <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
               <div class="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
                  <dt>
                     <div class="absolute rounded-md bg-theme-active p-3">
                        <font-awesome-icon size="1x" icon="fa-solid fa-book" class="text-white" />
                     </div>
                     <p class="ml-16 truncate text-sm font-medium text-gray-600 Booking Value uppercase">{{ bookingStats.total.name }}</p>
                  </dt>
                  <dd class="ml-16 flex items-baseline pb-6 sm:pb-7">
                     <p class="text-2xl font-semibold text-gray-900">{{ bookingStats.total.stat }}</p>
                     <div class="absolute inset-x-0 bottom-0 bg-gray-200 px-4 py-4 sm:px-6">
                        <div class="text-sm">
                           <div @click="redirectBookings()" class="cursor-pointer font-medium text-theme-active">
                              View all
                           </div>
                        </div>
                     </div>
                  </dd>
               </div>
               <div class="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
                  <dt>
                     <div class="absolute rounded-md bg-theme-active p-3">
                        <font-awesome-icon size="1x" icon="fa-solid fa-book" class="text-white" />
                     </div>
                     <p class="ml-16 truncate text-sm font-medium text-gray-600 Booking Value uppercase">{{ bookingStats.confirmed.name }}</p>
                  </dt>
                  <dd class="ml-16 flex items-baseline pb-6 sm:pb-7">
                     <p class="text-2xl font-semibold text-gray-900">{{ bookingStats.confirmed.stat }}</p>
                     <div class="absolute inset-x-0 bottom-0 bg-gray-200 px-4 py-4 sm:px-6">
                        <div class="text-sm">
                           <div @click="redirectBookings()" class="cursor-pointer font-medium text-theme-active">
                              View all
                           </div>
                        </div>
                     </div>
                  </dd>
               </div>
               <div class="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
                  <dt>
                     <div class="absolute rounded-md bg-theme-active p-3">
                        <font-awesome-icon size="1x" icon="fa-solid fa-book" class="text-white" />
                     </div>
                     <p class="ml-16 truncate text-sm font-medium text-gray-600 Booking Value uppercase">{{ bookingStats.pending.name }}</p>
                  </dt>
                  <dd class="ml-16 flex items-baseline pb-6 sm:pb-7">
                     <p class="text-2xl font-semibold text-gray-900">{{ bookingStats.pending.stat }}</p>
                     <div class="absolute inset-x-0 bottom-0 bg-gray-200 px-4 py-4 sm:px-6">
                        <div class="text-sm">
                           <div @click="redirectBookings()" class="cursor-pointer font-medium text-theme-active">
                              View all
                           </div>
                        </div>
                     </div>
                  </dd>
               </div>
               <div v-if="user.type == 'agent' && user.sub_type == null" class="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
                  <dt>
                     <div class="absolute rounded-md bg-theme-active p-3">
                        <font-awesome-icon size="1x" icon="fa-solid fa-book" class="text-white" />
                     </div>
                     <p class="ml-16 truncate text-sm font-medium text-gray-600 Booking Value uppercase">{{ bookingStats.subAgents.name }}</p>
                  </dt>
                  <dd class="ml-16 flex items-baseline pb-6 sm:pb-7">
                     <p class="text-2xl font-semibold text-gray-900">{{ bookingStats.subAgents.stat }}</p>
                     <div class="absolute inset-x-0 bottom-0 bg-gray-200 px-4 py-4 sm:px-6">
                        <div class="text-sm">
                           <div @click="redirectBookings()" class="cursor-pointer font-medium text-theme-active">
                              View all
                           </div>
                        </div>
                     </div>
                  </dd>
               </div>
               <div v-else-if="(user.type == 'corporate' || 'admin') || (user.type == 'agent' && user.sub_type == 'sub_agent')" class="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
                  <dt>
                     <div class="absolute rounded-md bg-theme-active p-3">
                        <font-awesome-icon size="1x" icon="fa-solid fa-book" class="text-white" />
                     </div>
                     <p class="ml-16 truncate text-sm font-medium text-gray-600 Booking Value uppercase">{{ bookingStats.cancelled.name }}</p>
                  </dt>
                  <dd class="ml-16 flex items-baseline pb-6 sm:pb-7">
                     <p class="text-2xl font-semibold text-gray-900">{{ bookingStats.cancelled.stat }}</p>
                     <div class="absolute inset-x-0 bottom-0 bg-gray-200 px-4 py-4 sm:px-6">
                        <div class="text-sm">
                           <div @click="redirectBookings()" class="cursor-pointer font-medium text-theme-active">
                              View all
                           </div>
                        </div>
                     </div>
                  </dd>
               </div>
            </dl>
         </div>
         <div>
            <h3 class="text-base font-semibold leading-6 text-gray-900">Sales Summary</h3>
            <dl class="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-2 md:divide-x">
               <div class="px-4 py-5 sm:p-6">
                  <dt class="text-base font-normal text-gray-900">{{ salesStats.total.name }}</dt>
                  <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
                     <div class="flex items-baseline text-2xl font-semibold text-theme-active">
                        {{ salesStats.total.stat }}
                     </div>
                  </dd>
               </div>
               <div class="px-4 py-5 sm:p-6">
                  <dt class="text-base font-normal text-gray-900">{{ salesStats.average.name }}</dt>
                  <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
                     <div class="flex items-baseline text-2xl font-semibold text-theme-active">
                        {{ salesStats.average.stat }}
                     </div>
                  </dd>
               </div>
            </dl>
         </div>

         <div class="flex flex-row items-center justify-between">
            <div>
               <h3 class="text-base font-semibold leading-6 text-gray-900">Bookings Growth</h3>
               <h5 class="text-gray-500 mt-1 text-sm">Showing growth by booking date</h5>
            </div>
            <div>
               <ListBox name="bookings_chart" :data="dataOptions" @selected="handleSelectedOption" />
            </div>
         </div>

         <div v-if="bookingsData" class="mt-8">
            <BarChart :chartData="bookingsData" />
         </div>

         <div class="flex flex-row items-center justify-between">
            <div>
               <h3 class="text-base font-semibold leading-6 text-gray-900">Sales Growth</h3>
               <h5 class="text-gray-500 mt-1 text-sm">Showing sales growth</h5>
            </div>
            <div>
               <ListBox name="sales_chart" :data="dataOptions" @selected="handleSelectedOption" />
            </div>
         </div>
         
         <div v-if="salesData" class="mt-8">
            <BarChart :chartData="salesData" />
         </div>

      </main>
   </div>  
   
   <Modal :show="openModal" :canClose="true" @closed="handleModalClose">
      <template v-slot:content>
         <div class="flex flex-col gap-12 py-24 px-11 lg:w-theme-dw md:mx-auto md:px-0">
            <div class="flex flex-col w-full mx-auto gap-5">
               <h2 class="mb-2">Enter your bank account details to receive the cashback</h2>
               <div class="flex flex-col gap-2">
                  <label for="reset-password" class="uppercase text-small text-theme-gray-4">Your Bank Name *</label>
                  <div class="flex flex-row items-center border border-theme-gray-7">
                     <input v-model="loyaltyRedeemForm.bankName.value" type="text" class="w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6">
                  </div>
                  <span v-if="loyaltyRedeemForm.bankName.error" class="text-ef-error">{{ loyaltyRedeemForm.bankName.errorMessage }}</span>
               </div>
               <div class="flex flex-col gap-2">
                  <label for="reset-password" class="uppercase text-small text-theme-gray-4">Your Account Title *</label>
                  <div class="flex flex-row items-center border border-theme-gray-7">
                     <input v-model="loyaltyRedeemForm.accountName.value" type="text" class="w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6">
                  </div>
                  <span v-if="loyaltyRedeemForm.accountName.error" class="text-ef-error">{{ loyaltyRedeemForm.accountName.errorMessage }}</span>
               </div>
               <div class="flex flex-col gap-2">
                  <label for="reset-password" class="uppercase text-small text-theme-gray-4">Your Bank Account Number or IBAN *</label>
                  <div class="flex flex-row items-center border border-theme-gray-7">
                     <input v-model="loyaltyRedeemForm.accountNumber.value" type="text" class="w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6">
                  </div>
                  <span v-if="loyaltyRedeemForm.accountNumber.error" class="text-ef-error">{{ loyaltyRedeemForm.accountNumber.errorMessage }}</span>
               </div>
               <div class="flex flex-col gap-2">
                  <label for="reset-password" class="uppercase text-small text-theme-gray-4">Bank Account Country Name *</label>
                  <div class="flex flex-row items-center border border-theme-gray-7">
                     <input v-model="loyaltyRedeemForm.countryName.value" type="text" class="w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6">
                  </div>
                  <span v-if="loyaltyRedeemForm.countryName.error" class="text-ef-error">{{ loyaltyRedeemForm.countryName.errorMessage }}</span>
               </div>
               <div class="flex flex-row items-center gap-4">
                  <div @click="handleModalSubmit" :class="isBusy ? 'cursor-not-allowed opacity-70' : 'bg-theme-active hover:bg-white cursor-pointer'" class="gap-4 uppercase rounded-full px-4 w-full hover:text-theme-active md:w-auto md:px-8 py-2 md:py-4 border-2 border-theme-active text-white text-theme-gray-2 text-size-2 flex items-center justify-center ml-auto transition-all ease-in-out duration-500">
                     Submit
                  </div>
               </div>
               <p v-if="loyaltyRedeemForm.error" class="text-ef-error">{{ loyaltyRedeemForm.error }}</p>
            </div>
         </div>
      </template>
   </Modal>
   <Loader :show="isLoading" />
</template>

<script setup lang="ts">
import { computed, ref, watch, onMounted } from 'vue'
import { BarChart } from 'vue-chart-3'
import { Chart, registerables } from "chart.js"
import ListBox from '@/widgets/ListBox.vue'
import { logout, isLoggedIn, handleApiError, disableScroll, enableScroll } from '@/utils/common'
import Dashboard from '@/composables/admin/Dashboard'
import Loader from '@/widgets/Loader.vue'
import router from '@/router'
import 'flatpickr/dist/flatpickr.css'
import Modal from '@/widgets/Modal.vue'
import flatPickr from 'vue-flatpickr-component'
import { NotificationType } from '@/types/index'
import Agent from '@/composables/admin/agent'
import { LOADER_MINIMUM_TIME } from '@/utils/constants'

type ChartOption = 'daily' | 'monthly' | 'weekly'
const pages = [
  { name: 'Projects', href: '#', current: false },
  { name: 'Project Nero', href: '#', current: true },
]
const props = defineProps(['user', 'agent', 'breadcrump'])
Chart.register(...registerables)
const bookingsData = ref<any>(null)
const salesData = ref<any>(null)
const isLoading = ref<boolean>(true)
const timeElapsed = ref<number>(0)
const { fetchData }  = Dashboard()
const dates = ref<string | null>(null)
const subAgent = ref<any>(props.agent ? props.agent : '')
const auth = isLoggedIn()
const loyaltyData = ref<any>()
const openModal = ref<boolean>(false)
const isBusy = ref<boolean>(false)
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const { requestRedeemLoyalty } = Agent()
const loyaltyRedeemForm = ref<any>({
   bankName: { value: '', error: false, errorMessage: '' },
   accountName: { value: '', error: false, errorMessage: '' },
   accountNumber: { value: '', error: false, errorMessage: '' },
   countryName: { value: '', error: false, errorMessage: '' }
})

const dateRangeConfig : any = {
   mode: 'range',
   altFormat: 'd-m-Y',
   altInput: true,
   dateFormat: 'd-m-Y'
}

onMounted(async () => {

   disableScroll()
   const startTime = performance.now()
   await fetchAllData()
   timeElapsed.value = performance.now() - startTime
   if (timeElapsed.value < LOADER_MINIMUM_TIME) {
      const diff = LOADER_MINIMUM_TIME - timeElapsed.value
      setTimeout(() => {
         enableScroll()
         isLoading.value = false
      }, diff)
   } else {
      enableScroll()
      isLoading.value = false
   }
})

const handleRedeemPoints = () => {

   openModal.value = true
}

const handleModalClose = () => openModal.value = false

const scopes = ref<any>(
   [
      {id: 'today', name: 'Today', isCurrent: false},
      {id: 'yesterday', name: 'Yesterday', isCurrent: false},
      {id: 'last_7_days', name: 'Last 7 Days', isCurrent: false},
      {id: 'this_month', name: 'This Month', isCurrent: false},
      {id: 'last_month', name: 'Last Month', isCurrent: false},
      {id: 'all_time', name: 'All Time', isCurrent: true},
      {id: 'custom', name: 'Custom Duration', isCurrent: false}
   ]
)

const validateForm = () : boolean => {

   let response = true
   loyaltyRedeemForm.value.bankName.error = false
   loyaltyRedeemForm.value.accountNumber.error = false
   loyaltyRedeemForm.value.countryName.error = false

   if (loyaltyRedeemForm.value.bankName.value == '') {
      loyaltyRedeemForm.value.bankName.error = true
      loyaltyRedeemForm.value.bankName.errorMessage = 'Required'
      response = false
   }

   if (loyaltyRedeemForm.value.accountNumber.value == '') {
      loyaltyRedeemForm.value.accountNumber.error = true
      loyaltyRedeemForm.value.accountNumber.errorMessage = 'Required'
      response = false
   }

   if (loyaltyRedeemForm.value.countryName.value == '') {
      loyaltyRedeemForm.value.countryName.error = true
      loyaltyRedeemForm.value.countryName.errorMessage = 'Required'
      response = false
   }
   
   if (loyaltyRedeemForm.value.accountName.value == '') {
      loyaltyRedeemForm.value.accountName.error = true
      loyaltyRedeemForm.value.accountName.errorMessage = 'Required'
      response = false
   }

   return response
}

const handleModalSubmit = async () => {

   const isValid = validateForm()

   if (isValid && !isBusy.value && auth && auth.user) {
      isBusy.value = true
      const response = await requestRedeemLoyalty(loyaltyRedeemForm.value)

      if (response.status == 200) {
      
         loyaltyData.value.has_redeemed = 1
         openModal.value = false
      } else {
         const error = handleApiError(response)
         loyaltyRedeemForm.value.error = error
      }

      isBusy.value = false
   }  
}

const dataOptions = [
   { title: 'Daily', description: '', value: 'daily', current: true },
   { title: 'Weekly', description: '', value: 'weekly', current: false },
   { title: 'Monthly', description: '', value: 'monthly', current: false },
]

const bookingStats = ref<any>({
      total: { id: 1, name: 'Requests', stat: 0 },
      confirmed: { id: 2, name: 'Confirmed', stat: 0 },
      pending: { id: 3, name: 'Pending', stat: 0 },
      subAgents: { id: 4, name: 'Agent Bookings', stat: 0 },
      cancelled: { id: 5, name: 'Cancelled Bookings', stat: 0 }
})

const notifClosed = () : void => {
   notif.value.show = false
}

const salesStats = ref<any>(
   {
      total: { id: 1, name: 'Total Sales', stat: 0 },
      commission: { id: 2, name: 'Total Commission', stat: 0 },
      pending: { id: 3, name: 'Pending Payments', stat: 0 },
      average: { id: 4, name: 'Avg Booking Value', stat: 0 }
   }
)

const handleSelectedOption = async (name: string, value: ChartOption) => {

   await fetchChartData(name, value) 
}

const redirectBookings = () => {

   if (props.user.type == 'admin') {

      router.push({name: 'AdminBookings'})
   } else if (props.user.type == 'agent') {

      router.push({name: 'UserBookings', params: {userType: 'agent', subUserId: ''}})
   } else if (props.user.type == 'corporate') {

      router.push({name: 'UserBookings', params: {userType: 'corporate', subUserId: ''}})
   }
}

const fetchChartData = async (chartName: string, option: ChartOption) => {

   const response = await fetchData(chartName, option, subAgent.value)

   if (response.status == 200) {
      
      if (chartName == 'bookings_chart') {

         prepareChartData('bookings', response)
      } else if (chartName == 'sales_chart') {
         
         prepareChartData('sales', response)
      }
   }
}

const fetchAllData = async () => {

   const response = await fetchData('all', 0, subAgent.value)
   if (response.status == 200) {
      prepareChartData('bookings', response)
      prepareChartData('sales', response)
      prepareBookingStats(response)
      prepareSalesStats(response)
   } else if (response.response && response.response.status == 401) {
      logout(router)
   }
   
   loyaltyData.value = response.data.loyalty_summary
}

const fetchStats = async () => {

   const activeScope = scopes.value.find((scope: any) => scope.isCurrent)

   if (activeScope) {

      const response = await fetchData('stats', activeScope.id, subAgent.value)
      if (response.status == 200) {
         
         prepareBookingStats(response)
         prepareSalesStats(response)
      }
   }
}

const prepareChartData = (chartName: string, response: any) => {

   if (chartName == 'bookings') {

      bookingsData.value = {
         labels: response.data.bookings_chart.data.labels,
         datasets: [
            {
               label: 'Bookings',
               data: response.data.bookings_chart.data.sets,
               backgroundColor: ['#77CEFF', '#0079AF', '#123E6B', '#97B0C4', '#A5C8ED', '#77CEFF','#77CEFF','#77CEFF','#77CEFF'],
            },
         ]
      }
   } else if (chartName == 'sales') {

      salesData.value = {
         labels: response.data.sales_chart.data.labels,
         datasets: [
            {
               label: 'Sales',
               data: response.data.sales_chart.data.sets,
               backgroundColor: ['#f87979', '#f87979', '#f87979', '#f87979', '#f87979', '#f87979','#f87979','#f87979','#f87979'],
            },
         ]
      }
   }
}

const prepareBookingStats = (response: any) => {

   const total = response.data.bookings_summary.total_bookings
   const confirmedBookings = response.data.bookings_summary.confirmed_bookings
   const pendingBookings = response.data.bookings_summary.pending_bookings
   const subAgentBookings = response.data.bookings_summary.sub_agent_bookings

   bookingStats.value.total.stat = response.data.bookings_summary.total_bookings
   bookingStats.value.confirmed.stat = `${confirmedBookings} (${Math.round((confirmedBookings/total) * 100) }%)`
   bookingStats.value.pending.stat = `${pendingBookings} (${Math.round((pendingBookings/total) * 100) }%)`
   bookingStats.value.subAgents.stat = `${subAgentBookings} (${(Math.round(subAgentBookings/total) * 100) }%)`
}

const prepareSalesStats = (response: any) => {

   salesStats.value.total.stat = response.data.sales_summary.total_sales
   salesStats.value.commission.stat = response.data.sales_summary.commission
   salesStats.value.pending.stat = response.data.sales_summary.pending_sales
   salesStats.value.average.stat = response.data.sales_summary.average
}

const handleScopeUpdate = async (item: any) => {

   scopes.value.forEach((scope: any) => {
      scope.isCurrent = false
   })

   scopes.value.find((scope: any) => scope.id === item.id).isCurrent = true
   dates.value = ''

   await fetchStats()
}

watch(() => props.agent, (newVallue, oldValue) => {
   
   subAgent.value = newVallue
   refetchData()
})

const refetchData = async () => {
   await fetchAllData()
}

watch(dates, (newValue, oldValue) => {

   if (newValue?.includes('to')) {
      
      scopes.value.forEach((scope: any) => {
         if (!scope.id.includes('custom')) {
            scope.isCurrent = false
         } else {
            scope.isCurrent = true
            const dateRange = `${newValue.split(' to ')[0]}_${newValue.split(' to ')[1]}`
            scope.id = `custom_${dateRange}`
         }
      })

      fetchStats().then(() => {
         
      })
   }
})

</script>