import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "px-4 sm:px-6 lg:px-8" }
const _hoisted_2 = { class: "sm:flex sm:items-center" }
const _hoisted_3 = { class: "sm:flex-auto" }
const _hoisted_4 = { class: "text-base font-semibold leading-6 text-gray-900" }
const _hoisted_5 = { class: "flex items-center gap-4" }
const _hoisted_6 = { class: "mt-4 sm:mt-0 sm:flex-none" }
const _hoisted_7 = { class: "mt-4 sm:mt-0 sm:flex-none" }
const _hoisted_8 = { class: "flex flex-wrap items-center justify-end gap-6 sm:flex-nowrap mb-6 my-12" }
const _hoisted_9 = { class: "order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:leading-7" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  key: 0,
  class: "flex items-center justify-start ml-auto w-full gap-8 mt-12"
}
const _hoisted_12 = { class: "flex flex-row gap-2" }
const _hoisted_13 = { class: "" }
const _hoisted_14 = { class: "flex flex-row gap-2" }
const _hoisted_15 = { class: "" }
const _hoisted_16 = { class: "mt-8 flow-root" }
const _hoisted_17 = { class: "-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" }
const _hoisted_18 = { class: "flex flex-col min-w-full py-2 align-middle sm:px-6 lg:px-8" }
const _hoisted_19 = {
  key: 0,
  class: "overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"
}
const _hoisted_20 = { class: "flex gap-2 w-full justify-end" }
const _hoisted_21 = { class: "p-input-icon-left" }
const _hoisted_22 = { class: "flex items-center gap-3" }
const _hoisted_23 = ["onClick"]
const _hoisted_24 = {
  key: 1,
  class: "overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"
}

import { ref, onMounted, watch, computed } from 'vue'
import { NotificationType } from '@/types/index'
import Admin from '@/composables/admin/index'
import { loadExternalCSS, logout } from '@/utils/common'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import router from '@/router'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import BookingsTable from '@/components/account/BookingsTable.vue'
import monthSelect from 'flatpickr/dist/plugins/monthSelect'

type Tab = 'agent' | 'sales'


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const { agentPerformance, salesReport } = Admin()
const agents = ref<any>(null)
const sales = ref<any>(null)
const salesCount = ref<number>(0)
const salesTotalAmount = ref<number>(0)
const isLoading = ref<boolean>(false)
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const filters = ref()
const filtersTwo = ref()
const loading = ref<boolean>(false)
const dates = ref<string | null>(null)
const date = ref<string | null>(null)
const months = ref<string | null>(null)
const dt = ref()
const exportFileName = ref<string>('report_all_time')
const currentTab = ref<Tab>('agent')
const currentScope = ref<any>()
const dateRangeConfig : any = {
   mode: 'range',
   altFormat: 'd-m-Y',
   altInput: true,
   dateFormat: 'd-m-Y'
}
const dateConfig: any = {
  mode: 'single',
  altFormat: 'd-m-Y',
  altInput: true,
  dateFormat: 'd-m-Y'
}
const monthRangeConfig : any = {
   mode: 'range',
   altFormat: 'm-Y',
   altInput: true,
   dateFormat: 'm-Y',
   plugins: [monthSelect({ dateFormat: 'Y-m', altFormat: 'F Y' })]
}
const scopes = computed(() => {
   return currentTab.value === 'agent' 
      ? [
         { id: 'today', name: 'Today', isCurrent: false },
         { id: 'yesterday', name: 'Yesterday', isCurrent: false },
         { id: 'last_7_days', name: 'Last 7 Days', isCurrent: false },
         { id: 'this_month', name: 'This Month', isCurrent: false },
         { id: 'last_month', name: 'Last Month', isCurrent: false },
         { id: 'all_time', name: 'All Time', isCurrent: true },
         { id: 'custom', name: 'Custom Duration', isCurrent: false }
        ]
      : [
         { id: 'this_month', name: 'This Month', isCurrent: false },
         { id: 'last_month', name: 'Last Month', isCurrent: false },
         { id: 'this_year', name: 'This Year', isCurrent: false },
         { id: 'last_year', name: 'Last Year', isCurrent: false },
         { id: 'all_time', name: 'All Time', isCurrent: true },
         { id: 'custom', name: 'Custom Duration', isCurrent: false },
         { id: 'single_date', name: 'Custom Date', isCurrent: false },
         { id: 'month_range', name: 'Month Range', isCurrent: false },
      ];
})

watch(scopes, (newScopes) => {
  currentScope.value = newScopes.find((scope) => scope.isCurrent)
}, { immediate: true })

onMounted(async () => {

   await fetchAgentPerfomance()  
   await fetchSalesReport()  

   loadExternalCSS('https://cdn.jsdelivr.net/npm/flatpickr/dist/plugins/monthSelect/style.css')
})

const fetchAgentPerfomance = async () => {
   isLoading.value = true
   const response = await agentPerformance(currentScope.value)
   isLoading.value = false

   if (response.status == 200) {      
      agents.value = response.data
   } else if (response.response && response.response.status == 401) {
      logout(router)
   }
}

const handleScopeUpdate = async (item: any) => {

   scopes.value.forEach((scope: any) => {
      scope.isCurrent = false
   })

   const foundScope = scopes.value.find((scope: any) => scope.id === item.id)
   if (foundScope) {
      foundScope.isCurrent = true
      currentScope.value = item
      exportFileName.value = `report_${foundScope.id}`
   }

   resetDateFields()

   if (currentTab.value == 'agent') {
      fetchAgentPerfomance()
   } else if (currentTab.value == 'sales') {
      fetchSalesReport()
   }
}

const resetDateFields = () => {
   dates.value = ''
   months.value = ''
   date.value = ''
}

const fetchSalesReport = async () => {
   isLoading.value = true
   const response = await salesReport(currentScope.value)
   isLoading.value = false
   if (response.status == 200) {
      sales.value = response.data.bookings
      salesCount.value = response.data.bookings_count
      salesTotalAmount.value = response.data.bookings_total
   } else if (response.response && response.response.status == 401) {
      logout(router)
   }
}

const handleTabChange = (tab: Tab) => {
   currentTab.value = tab
   resetDateFields()
}

const initFilters = () => {
   filters.value = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      "agent_name": { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      "agent_email": { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
   }
}

watch(dates, (newValue, oldValue) => {

   if (newValue?.includes('to')) {
      
      scopes.value.forEach((scope: any) => {
         if (!scope.id.includes('custom')) {
            scope.isCurrent = false
         } else if (scope.id.includes('custom')) {
            scope.isCurrent = true
            const dateRange = `${newValue.split(' to ')[0]}_${newValue.split(' to ')[1]}`
            scope.id = `custom_${dateRange}`
            currentScope.value = scope
            exportFileName.value = `report_${dateRange}`
         }
      })

      if (currentTab.value == 'agent') {
         fetchAgentPerfomance()
      } else if (currentTab.value == 'sales') {
         fetchSalesReport()
      }
   }
})

watch(date, (newValue, oldValue) => {

   scopes.value.forEach((scope: any) => {
      if (!scope.id.includes('single_date')) {
         scope.isCurrent = false
      } else if (scope.id.includes('single_date')) {
         scope.isCurrent = true
         scope.id = `single_date_${newValue}`
         currentScope.value = scope
         exportFileName.value = `report_${newValue}`
      }
   })

   if (currentTab.value == 'agent') {
      fetchAgentPerfomance()
   } else if (currentTab.value == 'sales') {
      fetchSalesReport()
   }
})

watch(months, (newValue, oldValue) => {

   if (newValue?.includes('to')) {
      
      scopes.value.forEach((scope: any) => {
         if (!scope.id.includes('month_range')) {
            scope.isCurrent = false
         } else if (scope.id.includes('month_range')) {
            scope.isCurrent = true
            const dateRange = `${newValue.split(' to ')[0]}_${newValue.split(' to ')[1]}`
            scope.id = `month_range_${dateRange}`
            currentScope.value = scope
            exportFileName.value = `report_${dateRange}`
         }
      })

      if (currentTab.value == 'agent') {
         fetchAgentPerfomance()
      } else if (currentTab.value == 'sales') {
         fetchSalesReport()
      }
   }
})

initFilters()

const clearFilter = () => {
   initFilters()
}

const exportCSV = (event: any) => {
   dt.value.exportCSV()
}


return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(currentTab.value == 'agent' ? 'Agent Performance Report' : 'Sales Report'), 1),
        _cache[9] || (_cache[9] = _createElementVNode("p", { class: "mt-2 text-sm text-gray-700" }, "View / download agent performace report, sales report", -1))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (handleTabChange('agent'))),
            class: _normalizeClass([currentTab.value !== 'agent' ? 'bg-white text-gray-500 border-gray-500 hover:bg-indigo-600 hover:text-white' : 'bg-indigo-600 text-white hover:bg-indigo-500 border-indigo-600', "block border rounded-md px-3 py-2 text-center text-sm font-semibold shadow-sm cursor-pointer focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"])
          }, " Agent Performance Report ", 2)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (handleTabChange('sales'))),
            class: _normalizeClass([currentTab.value !== 'sales' ? 'bg-white text-gray-500 border-gray-500 hover:bg-indigo-600 hover:text-white' : 'bg-indigo-600 text-white hover:bg-indigo-500 border-indigo-600', "block border rounded-md px-3 py-2 text-center text-sm font-semibold shadow-sm cursor-pointer focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"])
          }, " Sales Report ", 2)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scopes.value, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.id,
            class: _normalizeClass([item.isCurrent ? 'text-theme-active' : 'text-gray-700', "cursor-pointer"])
          }, [
            (!item.id.includes('custom') && !item.id.includes('single_date') && !item.id.includes('month_range'))
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  onClick: ($event: any) => (handleScopeUpdate(item))
                }, _toDisplayString(item.name), 9, _hoisted_10))
              : (item.id.includes('single_date'))
                ? (_openBlock(), _createBlock(_unref(flatPickr), {
                    key: 1,
                    modelValue: date.value,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((date).value = $event)),
                    config: dateConfig,
                    class: _normalizeClass([item.isCurrent ? 'text-theme-gray-5' : 'text-theme-active', "border px-3 outline-none"]),
                    placeholder: "Custom date"
                  }, null, 8, ["modelValue", "class"]))
                : (item.id.includes('custom'))
                  ? (_openBlock(), _createBlock(_unref(flatPickr), {
                      key: 2,
                      modelValue: dates.value,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((dates).value = $event)),
                      config: dateRangeConfig,
                      class: _normalizeClass([item.isCurrent ? 'text-theme-gray-5' : 'text-theme-active', "border px-3 outline-none"]),
                      placeholder: "Custom date range"
                    }, null, 8, ["modelValue", "class"]))
                  : (item.id.includes('month_range'))
                    ? (_openBlock(), _createBlock(_unref(flatPickr), {
                        key: 3,
                        modelValue: months.value,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((months).value = $event)),
                        config: monthRangeConfig,
                        class: _normalizeClass([item.isCurrent ? 'text-theme-gray-5' : 'text-theme-active', "border px-3 outline-none"]),
                        placeholder: "Custom months range"
                      }, null, 8, ["modelValue", "class"]))
                    : _createCommentVNode("", true)
          ], 2))
        }), 128))
      ])
    ]),
    (currentTab.value == 'sales')
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _cache[10] || (_cache[10] = _createElementVNode("div", { class: "text-base font-semibold leading-6 text-gray-900" }, "Total bookings:", -1)),
            _createElementVNode("div", _hoisted_13, _toDisplayString(salesCount.value), 1)
          ]),
          _createElementVNode("div", _hoisted_14, [
            _cache[11] || (_cache[11] = _createElementVNode("div", { class: "text-base font-semibold leading-6 text-gray-900" }, "Total sales amount:", -1)),
            _createElementVNode("div", _hoisted_15, _toDisplayString(salesTotalAmount.value.toFixed(2)), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, [
          (currentTab.value == 'agent')
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _createVNode(_component_DataTable, {
                  exportFilename: exportFileName.value,
                  ref_key: "dt",
                  ref: dt,
                  class: "text-sm",
                  filters: filters.value,
                  "onUpdate:filters": _cache[8] || (_cache[8] = ($event: any) => ((filters).value = $event)),
                  value: agents.value,
                  paginator: "",
                  showGridlines: "",
                  rows: 10,
                  dataKey: "id",
                  filterDisplay: "menu",
                  loading: loading.value,
                  globalFilterFields: ['agent_name','agent_email']
                }, {
                  header: _withCtx(() => [
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("span", _hoisted_21, [
                        _createVNode(_component_InputText, {
                          modelValue: filters.value['global'].value,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((filters.value['global'].value) = $event)),
                          placeholder: "Keyword Search"
                        }, null, 8, ["modelValue"])
                      ]),
                      _createVNode(_component_Button, {
                        type: "button",
                        icon: "pi pi-filter-slash",
                        label: "Clear",
                        outlined: "",
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (clearFilter()))
                      }),
                      _createVNode(_component_Button, {
                        icon: "pi pi-external-link",
                        label: "Export",
                        onClick: _cache[7] || (_cache[7] = ($event: any) => (exportCSV($event)))
                      })
                    ])
                  ]),
                  empty: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createTextVNode(" No locations added yet ")
                  ])),
                  loading: _withCtx(() => _cache[13] || (_cache[13] = [
                    _createTextVNode(" Loading bookings data. Please wait. ")
                  ])),
                  default: _withCtx(() => [
                    _createVNode(_component_Column, {
                      field: "agent_name",
                      header: "Name",
                      class: "w-[12rem]"
                    }, {
                      body: _withCtx(({ data }) => [
                        _createTextVNode(_toDisplayString(data.agent_name), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "agent_company",
                      header: "Company"
                    }, {
                      body: _withCtx(({ data }) => [
                        _createTextVNode(_toDisplayString(data.agent_company), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "agent_booking_count",
                      header: "Total Bookings",
                      class: "w-[12rem]"
                    }, {
                      body: _withCtx(({ data }) => [
                        _createTextVNode(_toDisplayString(data.agent_booking_count), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "agent_confirmed_booking_count",
                      header: "Confirmed Bookings",
                      class: "w-[12rem]"
                    }, {
                      body: _withCtx(({ data }) => [
                        _createTextVNode(_toDisplayString(data.agent_confirmed_booking_count), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "agent_pending_booking_count",
                      header: "Pending Bookings",
                      class: "w-[12rem]"
                    }, {
                      body: _withCtx(({ data }) => [
                        _createTextVNode(_toDisplayString(data.agent_pending_booking_count), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "agent_booking_total",
                      header: "Total Booking Amount",
                      class: "w-[12rem]"
                    }, {
                      body: _withCtx(({ data }) => [
                        _createTextVNode(_toDisplayString(data.agent_booking_total), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "agent_commission_total",
                      header: "Total Commission",
                      class: "w-[12rem]"
                    }, {
                      body: _withCtx(({ data }) => [
                        _createTextVNode(_toDisplayString(data.agent_commission_total), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      header: "Actions",
                      exportable: false,
                      class: ""
                    }, {
                      body: _withCtx(({ data }) => [
                        _createElementVNode("div", _hoisted_22, [
                          _createElementVNode("button", {
                            onClick: ($event: any) => (_unref(router).push({name: 'AdminAgentBookingsReport', params: {agentId: data.agent_id}})),
                            type: "button",
                            class: "whitespace-nowrap rounded rounded-bg bg-theme-button1-bg text-theme-button1-txt text-center px-2 py-2 uppercase text-xs"
                          }, " View Bookings ", 8, _hoisted_23)
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["exportFilename", "filters", "value", "loading"])
              ]))
            : (currentTab.value == 'sales')
              ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                  _createVNode(BookingsTable, {
                    subAgents: [],
                    bookings: sales.value,
                    isLoading: isLoading.value,
                    exportAs: exportFileName.value
                  }, null, 8, ["bookings", "isLoading", "exportAs"])
                ]))
              : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}
}

})