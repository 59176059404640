<template>

   <div class="car-info grid grid-cols-5 items-start gap-10 pb-6 pt-10">
      <div class="col-span-5 md:col-span-2">
         <img class="rounded-lg" :src="carr.image && carr.image != '' ? carr.image : '/assets/nocarfound.jpeg'" alt="">
      </div>
      <div class="col-span-5 md:col-span-3 flex flex-col gap-10">
         <div class="flex flex-col md:items-center md:flex-row">
            <div class="md:w-2/3 flex flex-col w-full items-start">
               <div class="text-theme-gray-5 text-size-6 font-bold text-center md:text-left w-full">
                  {{ isLead && isLead == '1' ? carr.type.name : (carr ? carr.name : '') }}
               </div>
               <div v-if="carr && carr.price" class="text-center text-size-7 font-bold text-theme-active w-full">
                  <ul class="flex flex-col w-full items-center justify-center md:items-start md:justify-start">
                     <li style="float:left"><span class="pricinglabel" v-if="userSearch.type == 'hourly'">Price for {{ (userSearch.hours ?? 0) / 10 }} day(s)</span> {{ `$${carr.price.toFixed(2)}` }}</li>
                     <li class="clearfix"></li>
                     <li style="float:left" v-if="userSearch.type == 'hourly'"><span class="pricinglabel">Daily Rate </span><span class="font-size:13px !important; font-weight:bold; color:black">{{ `$${carr.dailyrate.toFixed(2)}` }}</span></li>
                  </ul>
               </div>
               <div v-else class="rounded rounded-bg text-center min-w-[85px] py-2 px-2 my-2 uppercase text-xs bg-theme-button2-bg text-theme-button2-txt">
                  On Request
               </div>
            </div>
            <div v-if="canSelect" class="ml-auto">
               <div v-if="selectedCar && carr && selectedCar.id == carr.id" class="gap-2 uppercase rounded-full px-8 py-3 border-2 border-theme-gray-7 bg-theme-active text-white text-size-2 flex items-center justify-center cursor-pointer w-full md:w-auto md:ml-auto transition-all ease-in-out duration-500">
                  <span>Select</span>
                  <span>
                     <font-awesome-icon size="lg" icon="fa-solid fa-check" class="text-white" />
                  </span>
               </div>
               <div v-else @click="handleSelectCar(carr)" class="uppercase rounded-full px-8 border-2 border-theme-gray-7 bg-theme-gray-7 hover:bg-white text-theme-gray-2 text-size-2 flex items-center justify-center cursor-pointer py-3 mt-4 md:mt-0 md:ml-auto transition-all ease-in-out duration-500">
                  Select
               </div>
            </div>
         </div>
         
         <div class="flex flex-row">
            <div @click="toggleCarMoreInfo" class="flex flex-row items-center gap-3 group cursor-pointer">
               <div class="p-2 flex items-center justify-center rounded-full border border-theme-gray-7 bg-white group-hover:bg-theme-gray-7">
                  <font-awesome-icon size="xs" icon="fa-solid fa-chevron-down" class="text-theme-gray-6" />
               </div>
               <div class="uppercase text-theme-gray-2 text-size-2 tracking-wide group-hover:text-theme-gray-3">More info</div>
            </div>
            <div class="flex flex-col md:flex-row ml-auto gap-2 md:gap-6">
               <div class="flex flex-row items-center gap-5">
                  <div class="">
                     <span class="font-icon theme-icon users-icon text-theme-gray-9 text-[22px] md:text-[47px]"></span>
                  </div>
                  <div class="w-[30px] h-[30px] flex items-center justify-center rounded-full border border-theme-gray-7 bg-white text-theme-gray-6 text-size-2">
                     {{ carr ? carr.type.passengers : '' }}
                  </div>
               </div>
               <div class="flex flex-row items-center gap-5">
                  <div class="">
                     <span class="font-icon theme-icon suitcase-icon text-theme-gray-9 text-[22px] md:text-[47px]"></span>
                  </div>
                  <div class="w-[30px] h-[30px] flex items-center justify-center rounded-full border border-theme-gray-7 bg-white text-theme-gray-6 text-size-2">
                     {{ carr ? carr.type.bags : '' }}
                  </div>
               </div>
            </div>
         </div>
         <div class="flex flex-col gap-10 car-more-info" style="display:none">
            <div v-html="carr && carr.type && carr.type.description ? carr.type.description.replace(/\n/g, '<br>') : ''" class="font-light text-size-4 text-theme-gray-4"></div>
            <div class="grid grid-cols-1 gap-1 bg-theme-gray-1">
               <div class="col-span-1 md:col-span-1 py-6 px-4">
                  <div class="flex flex-col divide-y divide-theme-gray-9 gap-3">
                     <div class="flex flex-row items-start gap-6 py-2">
                        <div class="text-theme-gray-2 text-small uppercase">Terms & Conditions</div>
                        <div class="ml-auto text-theme-gray-5 text-size-3 text-left">
                           The price is inclusive of a car, driver, fuel, and taxes.
                           Driver & Contact Details:
                           We will advise the driver details by email 12 to 24 hrs before service.  If there is any trouble meeting the driver, please contact our customer service immediately by WhatsApp on +973 32141440, or the direct line +973 17001550.
                           Airport waiting time:<br>
                           <br>Transfer Free Wait Time 1 hour & Extra Waiting Fee as mentioned. Extras will be charged to the client unless otherwise advised by an Agent.
                           Hotel waiting time:<br>
                           <br>Transfer Free Wait Time 15 minutes & Extra Waiting Fee as mentioned. Extras will be charged to the client unless otherwise advised by an Agent.
                           <br><br>
                           Cancellation Policy:
                           <br>
                           General Terms:
                           Cancellation policies are set by the supplier and may change from one to another, so please read the cancellation policy carefully.
                           Unless otherwise mentioned, cancellation will be refundable if cancelled before 72 hours from the pick-up date and time. If a booking is confirmed for one or multiple days, the cancellation charges will apply to the total amount of the booking and not just the first days.
                           <br>ONCE YOU CONFIRM A BOOKING MEANS YOU HAVE READ, ACKNOWLEDGED AND ACCEPTED OUR CANCELLATION POLICY.
                           <br>Change to original booking: changes will be made if available. Changes may carry additional costs which should be approved by you before implementation. Once approved and paid changes will be implemented as required.
                           <br>Unutilized Days: There will be no refund for unutilized days. Once a booking is confirmed for a number of days and used for less days and returned before the originally booked end date, unused days will be fully charged.
                           <br>Last Minute Bookings: Bookings made less than 72 hours prior to pick up time are NON-REFUNDABLE once confirmed.
                           <br>No Shows: In case of No Shows for any reason, the full amount will be charged and not refundable.
                           <br>The service will be pre-confirmed when we receive your confirmation email. However, final confirmation will be sent after the receipt of the payment. Otherwise, it will be cancelled without prior notification if payment is not received prior to the free cancellation date and time and the terms of the rental.
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
// @ts-ignore
import $ from 'jquery'
import { Car as CarData, UserSearch } from '@/types'
import { retrieveUserSearch } from '@/services/search-service'
import { getHoursPerDay } from '@/utils/common'

const props = defineProps(['canSelect', 'car', 'selectedCar', 'isLead'])
const emit = defineEmits(['selected'])
const userSearch = ref<UserSearch>(retrieveUserSearch())
const days = ref<number>(0)

if (userSearch.value !== undefined && userSearch.value.hours) {
   days.value = userSearch.value.hours / getHoursPerDay()
}

const carr = computed(() => {
   const data = { ...props.car }

   if (userSearch.value) {

      data.dailyrate = 0
      data.price = 0

      if (userSearch.value.type == 'hourly' && props.car.chaufferLocation) {
         data.price = parseFloat(props.car.chaufferLocation.rate_per_hour) * (days.value *  (props.car && props.car.supplier && props.car.supplier.hours ? props.car.supplier.hours : 0))
         data.dailyrate = parseFloat(props.car.chaufferLocation.rate_per_hour) * (props.car.supplier.hours ?? 0)
      } else if (userSearch.value.type == 'distance' && props.car.transferLocation) {
         data.price = parseFloat(props.car.transferLocation.rate)
      }

      if (data.price > 0) {
         const priceWithoutCommission = data.price
         data.price = data.price / ((100-props.car.priceFactor)/100)
         data.dailyrate = data.dailyrate  / ((100-props.car.priceFactor)/100)

         if (props.car.discountFactor > 0) {
            const discount = priceWithoutCommission - (priceWithoutCommission * ((100-props.car.discountFactor)/100))
            data.price -= discount
            data.dailyrate -= discount
         }
      }
   }

   return data
})

const toggleCarMoreInfo = (event: any) => {
   $(event.currentTarget).closest('.car-info').find('.car-more-info').slideToggle()
}

const handleSelectCar = (car: CarData) => {
   emit('selected', car)
}

</script>