import { getAdminToken, getUserToken, getBackendBaseUrl } from "@/utils/common"
import { Locality } from '@/types/index'
import axios from "axios"

const baseUrl : string = getBackendBaseUrl()

const SiteSettings = () => {

   const getHeaders = () => { 
      return {
         'Authorization': `Bearer ${getAdminToken()}`,
         'Content-Type': 'application/json',
      }
   }
   
   const saveSiteSettings = async (cancellationHours: number) : Promise<any> => {

      const dataa = {
         cancellationHours: cancellationHours
      }

      try {
         const response = await axios.patch(baseUrl + 'api/admin/site-settings', dataa, {headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }
   
   const loadSiteSettings = async () : Promise<any> => {

      try {
         const response = await axios.get(baseUrl + 'api/site-settings/', {params: {}, headers: {}})
         return response
      } catch (err : any) {
         return err
      }
   }

   return {
      saveSiteSettings,
      loadSiteSettings
   }
}

export default SiteSettings