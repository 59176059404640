<template>
   <div class="px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-center">
         <div class="sm:flex-auto">
            <h1 class="text-base font-semibold leading-6 text-gray-900">{{ agent ? agent.name : '' }}</h1>
            <p class="mt-2 text-sm text-gray-700">View bookings of an agency</p>
         </div>
      </div>
      <div class="flex flex-wrap items-center justify-end gap-6 sm:flex-nowrap mb-6 my-12">
         <div class="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:leading-7">
            <div v-for="item in scopes" :key="item.id" :class="item.isCurrent ? 'text-theme-active' : 'text-gray-700'" class="cursor-pointer">
               <span v-if="!item.id.includes('custom')" @click="handleScopeUpdate(item)">{{ item.name }}</span>
               <flat-pickr v-else v-model="dates" :config="dateRangeConfig" :class="item.isCurrent ? 'text-theme-gray-5' : 'text-theme-active'" class="border px-3 outline-none" placeholder="Custom date range" />
            </div>
         </div>
      </div>
      <div class="mt-8 flow-root">
         <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="flex flex-col min-w-full py-2 align-middle sm:px-6 lg:px-8">
               <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <BookingsTable :subAgents="mySubAgents" :bookings="bookings" :isLoading="isLoading" />
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import BookingsTable from '@/components/account/BookingsTable.vue'
import Admin from '@/composables/admin'
import { isAdminLoggedIn, logout } from '@/utils/common'
import router from '@/router'

const { agentBookingsResport, agentSubAgents } = Admin()
const isLoading = ref<boolean>(true)
let authAdminUser = ref<any>(null)
const bookings = ref<any>(null)
const mySubAgents = ref([])
const agent = ref<any>(null)
const dateRangeConfig : any = {
   mode: 'range',
   altFormat: 'd-m-Y',
   altInput: true,
   dateFormat: 'd-m-Y'
}
const dates = ref<string | null>(null)
const scopes = ref<any>(
   [
      {id: 'today', name: 'Today', isCurrent: false},
      {id: 'yesterday', name: 'Yesterday', isCurrent: false},
      {id: 'last_7_days', name: 'Last 7 Days', isCurrent: false},
      {id: 'this_month', name: 'This Month', isCurrent: false},
      {id: 'last_month', name: 'Last Month', isCurrent: false},
      {id: 'all_time', name: 'All Time', isCurrent: true},
      {id: 'custom', name: 'Custom Duration', isCurrent: false}
   ]
)
const currentScope = computed(() => scopes.value.find((scope: any) => scope.isCurrent))

const props = defineProps(['agentId'])

onMounted(async () => {

   authAdminUser = isAdminLoggedIn()
   await fetchBookings()
   await fetchSubAgents()
})

watch(dates, (newValue, oldValue) => {

   if (newValue?.includes('to')) {
      
      scopes.value.forEach((scope: any) => {
         if (!scope.id.includes('custom')) {
            scope.isCurrent = false
         } else {
            scope.isCurrent = true
            const dateRange = `${newValue.split(' to ')[0]}_${newValue.split(' to ')[1]}`
            scope.id = `custom_${dateRange}`
         }
      })

      fetchBookings()
   }
})

const fetchBookings = async () => {

   isLoading.value = true
   let response : any

   response = await agentBookingsResport(props.agentId, currentScope.value)
   isLoading.value = false

   if (response.status == 200) {
      
      bookings.value = response.data
   } else if (response.response && response.response.status == 401) {
      logout(router)
   }
}

const fetchSubAgents = async () => {

   isLoading.value = true
   let response : any

   response = await agentSubAgents(props.agentId)
   isLoading.value = false

   if (response.status == 200) {
      
      agent.value = response.data.agent
      mySubAgents.value = response.data.subAgents
      console.log(agent.value)
   } else if (response.response && response.response.status == 401) {
      logout(router)
   }
}

const handleScopeUpdate = async (item: any) => {

   scopes.value.forEach((scope: any) => {
      scope.isCurrent = false
   })

   scopes.value.find((scope: any) => scope.id === item.id).isCurrent = true
   dates.value = ''

   await fetchBookings()
}

</script>