<template>
   <div class="px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-center">
         <div class="sm:flex-auto">
            <h1 class="text-base font-semibold leading-6 text-gray-900">{{ currentTab == 'agent' ? 'Agent Performance Report' : 'Sales Report' }}</h1>
            <p class="mt-2 text-sm text-gray-700">View / download agent performace report, sales report</p>
         </div>
         <div class="flex items-center gap-4">
            <div class="mt-4 sm:mt-0 sm:flex-none">
               <div @click="handleTabChange('agent')" :class="currentTab !== 'agent' ? 'bg-white text-gray-500 border-gray-500 hover:bg-indigo-600 hover:text-white' : 'bg-indigo-600 text-white hover:bg-indigo-500 border-indigo-600'" class="block border rounded-md px-3 py-2 text-center text-sm font-semibold shadow-sm cursor-pointer focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  Agent Performance Report
               </div>
            </div>
            <div class="mt-4 sm:mt-0 sm:flex-none">
               <div @click="handleTabChange('sales')" :class="currentTab !== 'sales' ? 'bg-white text-gray-500 border-gray-500 hover:bg-indigo-600 hover:text-white' : 'bg-indigo-600 text-white hover:bg-indigo-500 border-indigo-600'" class="block border rounded-md px-3 py-2 text-center text-sm font-semibold shadow-sm cursor-pointer focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  Sales Report
               </div>
            </div>
         </div>
      </div>
      <div class="flex flex-wrap items-center justify-end gap-6 sm:flex-nowrap mb-6 my-12">
         <div class="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:leading-7">
            <div v-for="item in scopes" :key="item.id" :class="item.isCurrent ? 'text-theme-active' : 'text-gray-700'" class="cursor-pointer">
               <span v-if="!item.id.includes('custom') && !item.id.includes('single_date') && !item.id.includes('month_range')" @click="handleScopeUpdate(item)">{{ item.name }}</span>
               <flat-pickr v-else-if="item.id.includes('single_date')" v-model="date" :config="dateConfig" :class="item.isCurrent ? 'text-theme-gray-5' : 'text-theme-active'" class="border px-3 outline-none" placeholder="Custom date" />
               <flat-pickr v-else-if="item.id.includes('custom')" v-model="dates" :config="dateRangeConfig" :class="item.isCurrent ? 'text-theme-gray-5' : 'text-theme-active'" class="border px-3 outline-none" placeholder="Custom date range" />
               <flat-pickr v-else-if="item.id.includes('month_range')" v-model="months" :config="monthRangeConfig" :class="item.isCurrent ? 'text-theme-gray-5' : 'text-theme-active'" class="border px-3 outline-none" placeholder="Custom months range" />
            </div>
         </div>
      </div>
      <div v-if="currentTab == 'sales'" class="flex items-center justify-start ml-auto w-full gap-8 mt-12">
         <div class="flex flex-row gap-2">
            <div class="text-base font-semibold leading-6 text-gray-900">Total bookings:</div>
            <div class="">{{salesCount}}</div>
         </div>
         <div class="flex flex-row gap-2">
            <div class="text-base font-semibold leading-6 text-gray-900">Total sales amount:</div>
            <div class="">{{ salesTotalAmount.toFixed(2) }}</div>
         </div>
      </div>
      <div class="mt-8 flow-root">
         <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="flex flex-col min-w-full py-2 align-middle sm:px-6 lg:px-8">
               <div v-if="currentTab == 'agent'" class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <DataTable
                     :exportFilename="exportFileName"
                     ref="dt"
                     class="text-sm"
                     v-model:filters="filters" 
                     :value="agents"
                     paginator showGridlines :rows="10" 
                     dataKey="id"
                     filterDisplay="menu" 
                     :loading="loading"
                     :globalFilterFields="['agent_name','agent_email']"
                  >
                     <template #header>
                        <div class="flex gap-2 w-full justify-end">
                           <span class="p-input-icon-left">
                              <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                           </span>
                           <Button type="button" icon="pi pi-filter-slash" label="Clear" outlined @click="clearFilter()" />
                           <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" />
                        </div>
                     </template>
                     <template #empty> No locations added yet </template>
                     <template #loading> Loading bookings data. Please wait. </template>
                     <Column field="agent_name" header="Name" class="w-[12rem]">
                        <template #body="{ data }">
                           {{ data.agent_name }}
                        </template>
                     </Column>
                     <Column field="agent_company" header="Company">
                        <template #body="{ data }">
                           {{ data.agent_company }}
                        </template>
                     </Column>
                     <Column field="agent_booking_count" header="Total Bookings" class="w-[12rem]">
                        <template #body="{ data }">
                           {{ data.agent_booking_count }}
                        </template>
                     </Column>
                     <Column field="agent_confirmed_booking_count" header="Confirmed Bookings" class="w-[12rem]">
                        <template #body="{ data }">
                           {{ data.agent_confirmed_booking_count }}
                        </template>
                     </Column>
                     <Column field="agent_pending_booking_count" header="Pending Bookings" class="w-[12rem]">
                        <template #body="{ data }">
                           {{ data.agent_pending_booking_count }}
                        </template>
                     </Column>
                     <Column field="agent_booking_total" header="Total Booking Amount" class="w-[12rem]">
                        <template #body="{ data }">
                           {{ data.agent_booking_total }}
                        </template>
                     </Column>
                     <Column field="agent_commission_total" header="Total Commission" class="w-[12rem]">
                        <template #body="{ data }">
                           {{ data.agent_commission_total }}
                        </template>
                     </Column>
                     <Column header="Actions" :exportable="false" class="">
                        <template #body="{ data }">
                           <div class="flex items-center gap-3">
                              <button @click="router.push({name: 'AdminAgentBookingsReport', params: {agentId: data.agent_id}})" type="button" class="whitespace-nowrap rounded rounded-bg bg-theme-button1-bg text-theme-button1-txt text-center px-2 py-2 uppercase text-xs">
                                 View Bookings
                              </button>
                           </div>
                        </template>
                     </Column>
                  </DataTable> 
               </div>
               <div v-else-if="currentTab == 'sales'" class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <BookingsTable :subAgents="[]" :bookings="sales" :isLoading="isLoading" :exportAs="exportFileName" />
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, computed } from 'vue'
import { NotificationType } from '@/types/index'
import Admin from '@/composables/admin/index'
import { loadExternalCSS, logout } from '@/utils/common'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import router from '@/router'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import BookingsTable from '@/components/account/BookingsTable.vue'
import monthSelect from 'flatpickr/dist/plugins/monthSelect'

type Tab = 'agent' | 'sales'

const { agentPerformance, salesReport } = Admin()
const agents = ref<any>(null)
const sales = ref<any>(null)
const salesCount = ref<number>(0)
const salesTotalAmount = ref<number>(0)
const isLoading = ref<boolean>(false)
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const filters = ref()
const filtersTwo = ref()
const loading = ref<boolean>(false)
const dates = ref<string | null>(null)
const date = ref<string | null>(null)
const months = ref<string | null>(null)
const dt = ref()
const exportFileName = ref<string>('report_all_time')
const currentTab = ref<Tab>('agent')
const currentScope = ref<any>()
const dateRangeConfig : any = {
   mode: 'range',
   altFormat: 'd-m-Y',
   altInput: true,
   dateFormat: 'd-m-Y'
}
const dateConfig: any = {
  mode: 'single',
  altFormat: 'd-m-Y',
  altInput: true,
  dateFormat: 'd-m-Y'
}
const monthRangeConfig : any = {
   mode: 'range',
   altFormat: 'm-Y',
   altInput: true,
   dateFormat: 'm-Y',
   plugins: [monthSelect({ dateFormat: 'Y-m', altFormat: 'F Y' })]
}
const scopes = computed(() => {
   return currentTab.value === 'agent' 
      ? [
         { id: 'today', name: 'Today', isCurrent: false },
         { id: 'yesterday', name: 'Yesterday', isCurrent: false },
         { id: 'last_7_days', name: 'Last 7 Days', isCurrent: false },
         { id: 'this_month', name: 'This Month', isCurrent: false },
         { id: 'last_month', name: 'Last Month', isCurrent: false },
         { id: 'all_time', name: 'All Time', isCurrent: true },
         { id: 'custom', name: 'Custom Duration', isCurrent: false }
        ]
      : [
         { id: 'this_month', name: 'This Month', isCurrent: false },
         { id: 'last_month', name: 'Last Month', isCurrent: false },
         { id: 'this_year', name: 'This Year', isCurrent: false },
         { id: 'last_year', name: 'Last Year', isCurrent: false },
         { id: 'all_time', name: 'All Time', isCurrent: true },
         { id: 'custom', name: 'Custom Duration', isCurrent: false },
         { id: 'single_date', name: 'Custom Date', isCurrent: false },
         { id: 'month_range', name: 'Month Range', isCurrent: false },
      ];
})

watch(scopes, (newScopes) => {
  currentScope.value = newScopes.find((scope) => scope.isCurrent)
}, { immediate: true })

onMounted(async () => {

   await fetchAgentPerfomance()  
   await fetchSalesReport()  

   loadExternalCSS('https://cdn.jsdelivr.net/npm/flatpickr/dist/plugins/monthSelect/style.css')
})

const fetchAgentPerfomance = async () => {
   isLoading.value = true
   const response = await agentPerformance(currentScope.value)
   isLoading.value = false

   if (response.status == 200) {      
      agents.value = response.data
   } else if (response.response && response.response.status == 401) {
      logout(router)
   }
}

const handleScopeUpdate = async (item: any) => {

   scopes.value.forEach((scope: any) => {
      scope.isCurrent = false
   })

   const foundScope = scopes.value.find((scope: any) => scope.id === item.id)
   if (foundScope) {
      foundScope.isCurrent = true
      currentScope.value = item
      exportFileName.value = `report_${foundScope.id}`
   }

   resetDateFields()

   if (currentTab.value == 'agent') {
      fetchAgentPerfomance()
   } else if (currentTab.value == 'sales') {
      fetchSalesReport()
   }
}

const resetDateFields = () => {
   dates.value = ''
   months.value = ''
   date.value = ''
}

const fetchSalesReport = async () => {
   isLoading.value = true
   const response = await salesReport(currentScope.value)
   isLoading.value = false
   if (response.status == 200) {
      sales.value = response.data.bookings
      salesCount.value = response.data.bookings_count
      salesTotalAmount.value = response.data.bookings_total
   } else if (response.response && response.response.status == 401) {
      logout(router)
   }
}

const handleTabChange = (tab: Tab) => {
   currentTab.value = tab
   resetDateFields()
}

const initFilters = () => {
   filters.value = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      "agent_name": { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      "agent_email": { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
   }
}

watch(dates, (newValue, oldValue) => {

   if (newValue?.includes('to')) {
      
      scopes.value.forEach((scope: any) => {
         if (!scope.id.includes('custom')) {
            scope.isCurrent = false
         } else if (scope.id.includes('custom')) {
            scope.isCurrent = true
            const dateRange = `${newValue.split(' to ')[0]}_${newValue.split(' to ')[1]}`
            scope.id = `custom_${dateRange}`
            currentScope.value = scope
            exportFileName.value = `report_${dateRange}`
         }
      })

      if (currentTab.value == 'agent') {
         fetchAgentPerfomance()
      } else if (currentTab.value == 'sales') {
         fetchSalesReport()
      }
   }
})

watch(date, (newValue, oldValue) => {

   scopes.value.forEach((scope: any) => {
      if (!scope.id.includes('single_date')) {
         scope.isCurrent = false
      } else if (scope.id.includes('single_date')) {
         scope.isCurrent = true
         scope.id = `single_date_${newValue}`
         currentScope.value = scope
         exportFileName.value = `report_${newValue}`
      }
   })

   if (currentTab.value == 'agent') {
      fetchAgentPerfomance()
   } else if (currentTab.value == 'sales') {
      fetchSalesReport()
   }
})

watch(months, (newValue, oldValue) => {

   if (newValue?.includes('to')) {
      
      scopes.value.forEach((scope: any) => {
         if (!scope.id.includes('month_range')) {
            scope.isCurrent = false
         } else if (scope.id.includes('month_range')) {
            scope.isCurrent = true
            const dateRange = `${newValue.split(' to ')[0]}_${newValue.split(' to ')[1]}`
            scope.id = `month_range_${dateRange}`
            currentScope.value = scope
            exportFileName.value = `report_${dateRange}`
         }
      })

      if (currentTab.value == 'agent') {
         fetchAgentPerfomance()
      } else if (currentTab.value == 'sales') {
         fetchSalesReport()
      }
   }
})

initFilters()

const clearFilter = () => {
   initFilters()
}

const exportCSV = (event: any) => {
   dt.value.exportCSV()
}

</script>